import React, { useState, useEffect } from 'react';
import http from "../../_services/http-common";
import { Card, Container, Button, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from "react-helmet";

const Kebijakanprivasi = () => {

    const [isLoading, setLoading] = useState(false);
    const [isSubmit, setSubmit] = useState(false);



    const [email, setEmail] = useState('')

    const updateEmail = e => {
        setEmail(e.target.value)
    }

    const submit = e => {
        e.preventDefault()

        if (email != '') {
            setLoading(true)
            const params = new URLSearchParams()
            params.append('email', email)
            http.post('ess/login/requestresetpassword', params).then(response => {

                if (response.data.status) {
                    toast.success('Email terkirim')
                    setSubmit(true)
                } else {
                    toast.error('Email tidak terkirim')
                }
                setLoading(false)

            }).catch(error => {
                toast.error('Email tidak terkirim')
                setLoading(false)
            })
        }


    }

    return (<Container>
        <Helmet>
            <style>{'body { padding-top:0px; }'}</style>
        </Helmet>
        <Row>
            {/* <Col md={1}></Col> */}
            <Col md={12}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop:'30px' }}>
                    <Card style={{ background: '#ffffff' }}>
                        <Card.Img variant="top" src="AppImages/brand/logo3.png" style={{ width: '100%', maxWidth: '450px' }} />
                        <Card.Body>
                            {/* <Card.Title className="mb-4">- KEBIJAKAN PRIVASI APLIKASI</Card.Title> */}

                            <div class="container">
                                <h1>Kebijakan Privasi Aplikasi Karyawan</h1>
                                <p><strong>Terakhir diperbarui:</strong> 05 November 2024</p>

                                <h2>Pendahuluan</h2>
                                <p>Aplikasi karyawan ini ("Aplikasi") dikelola oleh Rimau Group ("Perusahaan", "kami"). Kebijakan Privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, melindungi, dan berbagi informasi pribadi Anda saat Anda menggunakan aplikasi ini. Kami berkomitmen untuk melindungi privasi Anda sesuai dengan hukum perlindungan data yang berlaku.</p>
                                <p>Dengan menggunakan aplikasi ini, Anda menyetujui pengumpulan dan penggunaan data pribadi Anda sebagaimana diuraikan dalam Kebijakan Privasi ini.</p>

                                <h2>1. Informasi yang Kami Kumpulkan</h2>
                                <p>Kami mungkin mengumpulkan informasi berikut untuk mendukung fungsionalitas aplikasi:</p>

                                <h3>1.1 Informasi Pribadi</h3>
                                <ul>
                                    <li>Nama lengkap</li>
                                    <li>NIK/ID Karyawan</li>
                                    <li>Email dan nomor telepon</li>
                                    <li>Alamat tempat tinggal</li>
                                    <li>Informasi jabatan dan departemen</li>
                                </ul>

                                <h3>1.2 Data Penggunaan Aplikasi</h3>
                                <p>Informasi tentang bagaimana Anda menggunakan aplikasi, termasuk data log, alamat IP, jenis perangkat, dan data aktivitas aplikasi.</p>

                                <h3>1.3 Informasi Lokasi (Opsional)</h3>
                                <p>Dengan persetujuan Anda, kami dapat mengumpulkan data lokasi untuk keperluan absensi atau fitur lain yang terkait dengan lokasi kerja.</p>

                                <h2>2. Penggunaan Informasi</h2>
                                <p>Kami menggunakan informasi yang kami kumpulkan untuk:</p>
                                <ul>
                                    <li>Mengelola kehadiran dan absensi</li>
                                    <li>Melakukan komunikasi terkait pekerjaan</li>
                                    <li>Menyediakan layanan yang relevan untuk pengelolaan tugas karyawan</li>
                                    <li>Memenuhi persyaratan hukum atau peraturan</li>
                                    <li>Meningkatkan fungsionalitas aplikasi dan pengalaman pengguna</li>
                                </ul>

                                <h2>3. Berbagi Informasi</h2>
                                <p>Kami tidak akan menjual atau menyewakan data pribadi Anda kepada pihak ketiga. Kami hanya akan membagikan data Anda kepada pihak ketiga dalam kondisi berikut:</p>
                                <ul>
                                    <li>Untuk tujuan administrasi internal di lingkungan perusahaan</li>
                                    <li>Jika diwajibkan oleh hukum atau perintah pengadilan</li>
                                    <li>Jika diperlukan untuk melindungi hak dan kepentingan hukum Perusahaan</li>
                                </ul>

                                <h2>4. Keamanan Informasi</h2>
                                <p>Kami mengambil langkah-langkah yang wajar untuk melindungi informasi pribadi Anda dari akses yang tidak sah, penggunaan yang tidak sah, dan perubahan atau pengungkapan yang tidak sah. Namun, keamanan data yang disimpan secara digital tidak dapat dijamin sepenuhnya.</p>

                                <h2>5. Hak Karyawan</h2>
                                <p>Anda memiliki hak atas data pribadi Anda, termasuk hak untuk:</p>
                                <ul>
                                    <li>Mengakses dan memperbarui informasi pribadi Anda</li>
                                    <li>Menarik persetujuan yang telah diberikan sebelumnya (jika aplikasi memerlukan izin)</li>
                                    <li>Meminta penghapusan data pribadi Anda dalam kondisi tertentu</li>
                                </ul>
                                <p>Permintaan ini dapat diajukan dengan menghubungi tim SDM atau melalui fitur yang tersedia dalam aplikasi (jika ada).</p>

                                <h2>6. Perubahan Kebijakan Privasi</h2>
                                <p>Kebijakan Privasi ini dapat diperbarui dari waktu ke waktu. Kami akan memberitahu Anda tentang perubahan material melalui aplikasi atau metode lain yang sesuai. Anda dianjurkan untuk secara berkala meninjau Kebijakan Privasi ini.</p>

                                <h2>7. Hubungi Kami</h2>
                                <p>Jika Anda memiliki pertanyaan atau keluhan terkait Kebijakan Privasi ini, silakan hubungi kami di:</p>
                                <address>
                                Jl. A.M Sangaji JKLM No.11 9, RT.9/RW.7, Petojo Utara, Kecamatan Gambir, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10160<br />
                                    {/* Email: <a href="mailto:[Email Kontak]">[Email Kontak]</a><br /> */}
                                    Telepon: (021) 6322680
                                </address>
                            </div>

                        </Card.Body>
                    </Card>
                </div>


            </Col>
        </Row>
    </Container>)
};

export default Kebijakanprivasi;