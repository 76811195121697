import React, { useState, useEffect } from 'react';
import { Table, Container, Modal, Button, Form, FloatingLabel, Row, Col } from "react-bootstrap";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';

const Updatepassword = () => {

    const [isLoading, setLoading] = useState(false);
    const [isMatchPass, setMatchPass] = useState(true);

    const [password, setPassword] = useState('');
    const [newpassword, setNewpassword] = useState('');
    const [newpassword2, setNewpassword2] = useState('');
    const [isLogout, setLogout] = useState(false);

    const updatepassword = e => {
        setPassword(e.target.value)
    }
    const updatenewpassword = e => {
        setNewpassword(e.target.value)
    }
    const updatenewpassword2 = e => {
        setNewpassword2(e.target.value)
        if (e.target.value != '' && newpassword == e.target.value) {
            setMatchPass(true)
        } else {
            setMatchPass(false)
        }
    }

    const updateisLogout = e => {
        setLogout(e.target.checked)
    }

    function submitLogin() {
        // console.log('password', password)
        // console.log('newpassword', newpassword)
        // console.log('newpassword2', newpassword2)
        // console.log('isLogout', isLogout)

        if (password != '' &&
            newpassword != '' &&
            newpassword2 != '' && newpassword == newpassword2) {

            setLoading(true)

            const params = new URLSearchParams()
            params.append('old_password', password)
            params.append('new_password', newpassword)
            params.append('is_logout', isLogout)

            http.post('ess/profile/password', params).then(response => {
                console.log(response.data)

                setTimeout(() => {
                    setLoading(false)
                }, 1000)

                if (response.data.status) {
                    toast.success("Password berhasil diubah")
                    setTimeout(() => {
                        window.location.replace('/profile')
                    }, 1000)
                } else {
                    toast.error("Password lama salah")
                }
            }).catch(error => {
                console.log(error)
                toast.error("Terjadi kesalahan, cobalah beberapa saat lagi")
                setLoading(false)
            });

        } else {
            toast.error("Mohon isi semua form")
        }


    }

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={4} md={4}>
                    <h3>Ubah Password</h3>
                    <hr />
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Password Lama"
                        className="mb-3"
                    >
                        <Form.Control placeholder='Password Lama' value={password} onChange={updatepassword} type="text" />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label="Password Baru"
                        className="mb-3"
                    >
                        <Form.Control placeholder='Password Baru' value={newpassword} onChange={updatenewpassword} type="text" />
                    </FloatingLabel>

                    <FloatingLabel
                        controlId="floatingInput"
                        label="Re - Password Baru"
                        className="mb-3"
                    >
                        <Form.Control placeholder='Re - Password Baru' value={newpassword2} onChange={updatenewpassword2} type="text" />

                        {(isMatchPass == false) ? <Form.Text className="text-muted">
                            Re-Password tidak cocok dengan Password Baru
                        </Form.Text> : ''}

                    </FloatingLabel>


                    <Form.Group className="mb-3 mt-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" onChange={updateisLogout} label="Keluar dari semua device login" />

                        {(isLogout) ? <Form.Text className="text-muted">
                            Semua device yang sudah login akan dikeluarkan kecuali ini!
                        </Form.Text> : ''}


                    </Form.Group>

                    <Button className="float-end" disabled={isLoading} type="submit" onClick={submitLogin}>
                        {isLoading ? 'Loading…' : 'Kirim'}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default Updatepassword;