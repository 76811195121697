import React, { useState } from 'react';
import { Card, Container, Button, Row, Col, Form, FloatingLabel, InputGroup, FormControl } from "react-bootstrap";
import http from "../../_services/http-common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
// import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";


const Force = () => {

    var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjUxIn0.BRyaAG-1aX8KWw963qxoEmP4M25wark7rE95GDpFDl4";
    var decoded = jwt_decode(token);

    console.log(decoded.id);

    return (<Container>Loading . . .</Container>)
};

export default Force;