import axios from "axios"

const REST_URI = 'https://api.rimauapk.com/';
// const REST_URI = 'http://localhost:8080/mp-rest/';


const api_key = localStorage.getItem('api_key');

// console.log('api_key',api_key);

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-API-KEY': (api_key!='' && api_key!=null && typeof api_key !== 'undefined') ? api_key : ''
}

export default axios.create({
  baseURL: REST_URI,
  headers: headers
});
