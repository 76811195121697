import React, { useState, useEffect, useCallback } from 'react';
import { Form, Container, Card, Button, Modal, FloatingLabel, Row, Col } from "react-bootstrap";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';
import { toast } from 'react-toastify';

const Suggestions = () => {


    // const [announcements, setAnnouncements] = useState([]);
    const [title, setTitle] = useState('');
    const [suggestion, setSuggestion] = useState('');

    const [isLoading, setLoading] = useState(false);

    const updateTitle = e => {
        setTitle(e.target.value)
    }
    const updateSuggestion = e => {
        setSuggestion(e.target.value)
    }

    function submitLogin() {
        if (title != '' &&
            suggestion != '') {
            setLoading(true)

            const params = new URLSearchParams()
            params.append('title', title)
            params.append('suggestion', suggestion)

            http.put('ess/profile/suggestions', params).then(response => {

                setLoading(false)
                if (response.data.status) {
                    toast.success("Terima kasih, kritik dan saran Anda berhasil terkirim")
                    setTitle('')
                    setSuggestion('')
                }


            }).catch(error => {
                toast.error("Tejadi kesalahan, cobalah beberapa saat lagi")
                console.log(error);
                setLoading(false);
            })

        } else {
            toast.warn("Mohon isi semua form")
        }
    }


    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={6} md={6}>

                    <h3>Kritik dan Saran</h3>
                    <span>Berikan kami kritik & saran yang membangun</span>

                    <hr />

                    <FloatingLabel
                        controlId="floatingInput"
                        label="Judul"
                        className="mb-3"
                    >
                        <Form.Control placeholder='Judul' value={title} onChange={updateTitle} type="text" />
                    </FloatingLabel>

                    <FloatingLabel controlId="floatingTextarea2" label="Kritik dan saran...">
                        <Form.Control
                            as="textarea"
                            placeholder="Kritik & Saran..."
                            style={{ height: '300px' }}
                            value={suggestion}
                            onChange={updateSuggestion}
                        />
                    </FloatingLabel>

                    <br />

                    <Button className="float-end" disabled={isLoading} type="submit" onClick={submitLogin}>
                        {isLoading ? 'Loading…' : 'Kirim'}
                    </Button>

                </Col>
            </Row>
        </Container>
    );
};

export default Suggestions;