import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Navbar, Container, Nav, Modal, Row, Col, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import http from '../_services/http-common';

import { ProfileContext } from '../_context/ProfileContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MainHeader = () => {

    
    const logmeout = e => {

        e.preventDefault();

        http.delete('ess/login/logout').then(response => {
            // console.log(response.data);
            // localStorage.removeItem('auth');
            // localStorage.removeItem('api_key');
            localStorage.clear();
            window.location.replace('/');
            setProfile({});
        }).catch(error => {
            console.log(error);
        });

    }

    const [profile, setProfile] = useContext(ProfileContext);

    // console.log(profile);

    let isAuth = localStorage.getItem('auth');

    let navigation_panel = '';

    const [showModalMobileApps, setShowModalMobileApps] = useState(true);

    if (isAuth) {
        // setShowModalMobileApps(true);
        navigation_panel = (<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
            <Container>
                <Navbar.Brand href="/">
                    {/* <img
                    alt=""
                    src="AppImages/brand/avatar6.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                /> */}

                    {(profile.photo_uri != '') ?
                        <img className="d-inline-block align-top" src={profile.photo_uri} width="30"
                            style={{ marginRight: '7px', border: '1px solid #f2f2f2', borderRadius: '50%' }} />
                        :
                        ''
                    }

                    {profile.name_view}</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink className={'nav-link'} to='/attendance'><FontAwesomeIcon icon="fa-solid fa-clipboard-user" /> Absensi</NavLink>
                        {/* {(profile.cuti_type == 'Tahunan') ? <NavLink className={'nav-link'} to='/permissions'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Perizinan</NavLink> : ''} */}
                        <NavLink className={'nav-link'} to='/permissions'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Perizinan</NavLink>
                        {(parseInt(profile.count_manager_id) > 0) ? <NavLink className={'nav-link'} to='/listpermissions'><FontAwesomeIcon icon="fa-solid fa-list-check" /> Perizinan (Team)</NavLink> : ''}
                        <NavLink className={'nav-link'} to='/profile'><FontAwesomeIcon icon="fa-solid fa-user-tie" /> Profil</NavLink>
                    </Nav>
                    <Nav>
                        <NavLink className={'nav-link'} to='#' onClick={() => setShowModalMobileApps(true)}><FontAwesomeIcon icon="fa-solid fa-mobile-screen" /> Mobile Apps</NavLink>
                        {/* <NavLink className={'nav-link'} to='/announcement'><FontAwesomeIcon icon="fa-solid fa-bullhorn" /> Pengumuman</NavLink> */}
                        <NavLink className={'nav-link'} to='/suggestions'><FontAwesomeIcon icon="fa-solid fa-box-open" /> Kotak Saran</NavLink>
                        <Nav.Link href="#" onClick={logmeout}><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Log Out</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>);
    }

    return (<div><ToastContainer />{navigation_panel}
     <>
                <Modal show={showModalMobileApps}
                    backdrop="static"
                    size="lg"
                    onHide={() => setShowModalMobileApps(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Peluncuran <i>Mobile Apps</i></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col xs={12} md={4} style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                                    <Image src="https://api.rimauapk.com/uploads/essapps.png" alt="Logo" rounded 
                                    style={{ width: '100%', maxWidth: '200px' }} />
                                </Col>
                                <Col xs={12} md={8}>
                                    Dapatkan notifikasi dan update modul terbaru dengan mengunduh aplikasi <i>Ess Rimau</i>.
                                    <br/>
                                    <Button variant="outline-primary"
                                    as="a"
                                    href="https://api.rimauapk.com/uploads/Dokumentasi_Mobile_Apps.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >Lihat Panduan Aplikasi</Button>  <i>(PDF File)</i>
                                    <hr/>
                                    <b>Android</b>
                                    <ul>
                                        <li>Buka <i><a href="https://play.google.com/">PlayStore</a></i></li>
                                        <li>Ketikan dipencarian <i>"Ess Rimau"</i></li>
                                    </ul>
                                    Atau klik, <br />
                                    <a href='https://play.google.com/store/apps/details?id=app.rimau.ess'>
                                        <Image src="https://api.rimauapk.com/uploads/btn-playstore.png" alt="Logo" rounded style={{ width: '100%', maxWidth: '150px' }} />
                                    </a>

                                    <hr />
                                    <b>IOS</b>
                                    <br/>
                                    <i>~ On process.</i>
                                </Col>

                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalMobileApps(false)}>
                            Tutup
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
    </div>);
    // return (<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
    //     <Container>
    //         <Navbar.Brand href="#home">ini : {value}</Navbar.Brand>
    //         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //         <Navbar.Collapse id="responsive-navbar-nav">
    //             <Nav className="me-auto">
    //                 <NavLink className={'nav-link'} to='/welcome'><FontAwesomeIcon icon="fa-solid fa-clipboard-user" /> Attendance</NavLink>
    //                 <NavLink className={'nav-link'} to='/products'><FontAwesomeIcon icon="fa-solid fa-file-pen" /> Permissions</NavLink>
    //                 <NavLink className={'nav-link'} to='/products'><FontAwesomeIcon icon="fa-solid fa-user-tie" /> Profile</NavLink>
    //             </Nav>
    //             <Nav>
    //                 <Nav.Link href="#" onClick={logmeout}><FontAwesomeIcon icon="fa-solid fa-right-from-bracket" /> Log Out</Nav.Link>
    //             </Nav>
    //         </Navbar.Collapse>
    //     </Container>
    // </Navbar>);
};

export default MainHeader;