import React, { useState, useEffect, useCallback } from 'react';
import {
    Form, Container, Card, Button, Modal, FloatingLabel,
    Row, Col, Table, Alert
} from "react-bootstrap";
import http from "../../_services/http-common";

import {
    Link, useParams
} from "react-router-dom";

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';
import { toast } from 'react-toastify';

const Detailchangeprofile = () => {

    const { id } = useParams();

    const [existing, setExisting] = useState(null);
    const [isLoading, setLoading] = useState(false);

    function loadHistory() {
        http.get('ess/profile/changeprofiledetails', {
            params: {
                change_profile_id: id
            }
        }).then(response => {

            console.log(response.data.data)
            setExisting(response.data.data)

        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        loadHistory()

    }, []);

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={8} md={8}>

                    <h3>Detail Perubahan</h3>
                    <hr />

                    {(existing) ?

                        <>
                            {(parseInt(existing.status) == 0)
                                ? <Alert variant='primary'>
                                    Menuggu tanggapan dari Admin HRD
                                </Alert> : (
                                    (parseInt(existing.status) == 1)
                                        ? <Alert variant='success'>
                                            Perubahan disetujui
                                        </Alert>
                                        : <Alert variant='danger'>
                                            Perubahan tidak disetujui. | {existing.notes}
                                        </Alert>
                                )}



                            <Table responsive bordered gap={3} style={{ background: '#ffffff' }}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20%', textAlign: 'center' }}>Atribut</th>
                                        <th style={{ width: '40%', textAlign: 'center' }}>Sebelum</th>
                                        <th style={{ textAlign: 'center' }}>Sesudah</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {existing.formData.map((obj, i) => {

                                        var tr = (obj.is_master == 1) ?
                                            <tr key={i + 1}>
                                                <td>{obj.label}</td>
                                                <td>{obj.master_before}</td>
                                                {(obj.is_change == 1)
                                                    ? <td style={{ background: '#e7ffe8' }}>{obj.master_after}</td> : <td>{obj.master_after}</td>}

                                            </tr>
                                            :
                                            <tr key={i + 1}>
                                                <td>{obj.label}</td>
                                                <td>{obj.before}</td>
                                                {(obj.is_change == 1) ? <td style={{ background: '#e7ffe8' }}>{obj.value}</td> : <td>{obj.value}</td>}

                                            </tr>;

                                        return tr


                                    })}
                                </tbody>
                            </Table>
                        </>


                        : ''}




                </Col>
            </Row>
        </Container>
    );
};

export default Detailchangeprofile;