import React, { useState } from 'react';
import { Card, Container, Button, Row, Col, Form, FloatingLabel, InputGroup, FormControl } from "react-bootstrap";
import http from "../../_services/http-common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
// import 'react-toastify/dist/ReactToastify.css';


const Login = () => {

    const [isLoading, setLoading] = useState(false);

    const [nik, setNik] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);

    const updateNik = e => {
        setNik(e.target.value)
    }



    const updatePassword = e => {

        setPassword(e.target.value)
    }

    const updateRemember = e => {
        setRemember(e.target.checked)
    }



    const submitLogin = e => {

        e.preventDefault();
        console.log(nik, password);
        submitLoginAction()


    }

    function submitLoginAction() {
        if (nik != '' && nik != null &&
            password != '' && password != null) {

            setLoading(true);

            const params = new URLSearchParams()
            params.append('nik', nik)
            params.append('password', password)
            params.append('remember', remember)


            http.post('ess/login/auth', params).then(response => {
                // console.log(response.data);
                localStorage.setItem('auth', true);
                localStorage.setItem('api_key', response.data.data.api_key);

                window.location.replace('/');

            }).catch(error => {
                console.log(error);
                setLoading(false);
                toast.error("NIK & Password tidak cocok")
            });

        } else {
            toast.warn("Mohon isi semua form")
        }
    }

    const actionOnKeyUp = e => {
        if (e.key == 'Enter') {
            submitLoginAction();
        }
    }

    return (<Container>
        <Helmet>
            <style>{'body { padding-top:0px; }'}</style>
        </Helmet>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ background: '#f5f5f5' }}>
                        <Card.Img variant="top" src="AppImages/brand/logo3.png" />
                        <Card.Body>
                            <Card.Title className="mb-4">Login</Card.Title>
                            <>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="NIK (Nomor Induk Karyawan)"
                                    className="mb-3"
                                >
                                    <Form.Control placeholder='NIK' value={nik} onChange={updateNik} onKeyUp={actionOnKeyUp} type="text" />
                                </FloatingLabel>

                                <FloatingLabel controlId="floatingPassword" label="Password">
                                    <Form.Control placeholder='Password' value={password} onKeyUp={actionOnKeyUp} onChange={updatePassword} type="password" />
                                </FloatingLabel>

                                <Form.Group className="mb-3 mt-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" value={remember} onChange={updateRemember} label="Tetap login" />
                                </Form.Group>

                            </>

                            <div className="mt-4">
                                <Button href="/forgotpassword" variant="outline-primary">Lupa Password ?</Button>
                                <Button className="float-end" disabled={isLoading} onClick={submitLogin} variant="primary">
                                    {isLoading ? 'Loading…' : 'Kirim'}
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </div>

            </Col>
        </Row>




    </Container>)
};

export default Login;