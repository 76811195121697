import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyBgGlupP9nbr7J3R6Oy7q2EA6NQ5xQSgVo",
    authDomain: "ess-rimau.firebaseapp.com",
    projectId: "ess-rimau",
    storageBucket: "ess-rimau.appspot.com",
    messagingSenderId: "347730934426",
    appId: "1:347730934426:web:83bcfaa59bb12787b03d23"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BDnbEoZTZcPXwob8PQJYoHIf9w5lhVJQnxjBbCPE2uIUKc6iDB0dukqBlJUVaAnjbL1ypOsGzChVd7XlsUq4BPY' }).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });