import React, { useState, useEffect, useCallback } from 'react';
import {
    Form, Container, Card, Button, Modal, FloatingLabel,
    Row, Col, Table
} from "react-bootstrap";

import {
    Link
} from "react-router-dom";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';
import { toast } from 'react-toastify';

const Historychangeprofile = () => {

    const [histories, setHistory] = useState([]);
    const [isLoading, setLoading] = useState(false);

    function loadHistory() {
        http.get('ess/profile/changeprofilelist').then(response => {

            console.log(response.data.data)
            setHistory(response.data.data)

        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        loadHistory()

    }, []);

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={8} md={8}>

                    <h3>Riwayat Perubahan Data</h3>
                    <hr />

                    {(histories.length > 0) ? <Table responsive bordered striped gap={3} style={{ background: '#ffffff' }}>

                        <thead>
                            <tr>
                                <th style={{ width: '1%', textAlign: 'center' }}>No</th>
                                <th style={{ textAlign: 'center' }}>Tanggal Request</th>
                                <th style={{ width: '35%', textAlign: 'center' }}>Tanggal Tanggapan</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>HRD Staff</th>
                                <th style={{ width: '15%', textAlign: 'center' }}>Status</th>
                                <th style={{ width: '15%', textAlign: 'center' }}></th>

                            </tr>
                        </thead>
                        <tbody>
                            {histories.map((history, index) => {
                                return <tr key={index + 1}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                    <td style={{ whiteSpace: 'nowrap' }}>
                                        <Moment format="DD MMM YYYY HH:mm" withTitle>
                                            {history.created_at}
                                        </Moment>
                                    </td>
                                    {(history.action_by != null && history.action_by != '' && history.action_by != 'null')
                                        ? <td style={{ whiteSpace: 'nowrap' }}>
                                            <Moment format="DD MMM YYYY HH:mm" withTitle>
                                                {history.updated_at}
                                            </Moment>
                                        </td>
                                        : <td>-</td>}

                                    {(history.action_by != null && history.action_by != '' && history.action_by != 'null')
                                        ? <td style={{ whiteSpace: 'nowrap' }}>
                                            {history.action_by_name}
                                        </td>
                                        : <td>-</td>}

                                    {(parseInt(history.status) == 0) ? <td style={{ whiteSpace: 'nowrap', color: 'blue' }}>Menunggu tanggapan</td> :
                                        (
                                            (parseInt(history.status) == 1) ? <td style={{ whiteSpace: 'nowrap', color: 'green' }}>Disetujui</td> : <td style={{ whiteSpace: 'nowrap', color: 'red' }}>Ditolak</td>
                                        )
                                    }

                                    <td style={{ textAlign: 'center' }}>
                                        {/* <Link
                                            to={{
                                                pathname: "/detailchangeprofile/${his}",
                                                data: history // your data array of objects
                                            }}> */}
                                        <Link
                                            to={`/detailchangeprofile/${history.change_profile_id}`}>
                                            <Button size='sm' variant="secondary">Lihat Detail</Button>
                                        </Link>

                                    </td>

                                </tr>
                            })}
                        </tbody>
                    </Table> : 'Belum ada riwayat'}


                </Col>
            </Row>
        </Container>
    );
};

export default Historychangeprofile;