import React, { useState, useEffect, useContext, useRef } from 'react';
import {
    Table, Container, Card, Button, Modal,
    Alert, Row, Col, Form
} from "react-bootstrap";
import http from "../../_services/http-common";

import { ProfileContext } from '../../_context/ProfileContext';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';

const Createpermission = () => {

    const inputFoto = useRef()

    const [profile, setProfile] = useContext(ProfileContext);

    const [isLoading, setLoading] = useState(false);


    const [categories, setCategories] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [holidaysstatus, setHolidaysstatus] = useState(false);
    const [category_desc, setDesc] = useState('');

    const [category, setCategory] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');

    const [count_weekend, setcount_weekend] = useState(1);
    const [count_weekend_minggu, setcount_weekend_minggu] = useState(1);
    const [count_holidays, setcount_holidays] = useState(1);

    const [exclude, setExclude] = useState([]);



    const [workingday, setWorkingday] = useState(0);
    const [reason, setReason] = useState('');
    const [substitution_by, setSubstitution_by] = useState('');
    const [type, setType] = useState('');
    const [typeLabel, setTypeLabel] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);



    const updateCategory = e => {
        setCategory(e.target.value)
        setDesc('')
        setType('')

        let total_sisa_cuti = parseInt(profile.cuti) + parseInt(profile.cuti_before);

        for (let i = 0; i < categories.length; i++) {
            const d = categories[i];
            if (d.leave_category_id == e.target.value) {
                // console.log(d.is_cuti);
                setDesc(d.descriptions)

                if (parseInt(d.is_cuti) == 0) {
                    setTypeLabel('Tidak memotong cuti / gaji')
                } else if (parseInt(d.is_cuti) == 2) {
                    setTypeLabel('Akan memotong gaji')
                    setType('2')
                } else if (parseInt(d.is_cuti) == 3) {
                    setTypeLabel('Akan memotong cuti (sisa cuti saat ini : ' + total_sisa_cuti + ')')
                    setType('1')
                } else {

                    if (total_sisa_cuti > 0) {
                        setType('1')
                        setTypeLabel('Akan memotong cuti (Sisa cuti saat ini : ' + total_sisa_cuti + ')')
                    } else if (total_sisa_cuti <= 0) {
                        setType('2')
                        setTypeLabel('Saat ini Anda tidak memiliki sisa cuti, sehingga akan memotong gaji Anda')
                    }
                }

                console.log(type)
            }

        }
    };

    const fetchHoliday = () => {
        return new Promise((resolve, reject) => {
            http.get('ess/attendance/holiday').then(response => {
                console.log('load libur dulu')
                setHolidays(response.data.data);
                resolve(true)
            }).catch(error => {
                console.log(error)
                reject(true)
            });
        })
    }


    function loadCategories() {

        fetchHoliday().then((response) => {
            setHolidaysstatus(true)
            console.log('load kategory')
            http.get('ess/attendance/leavecategory').then(response => {
                // console.log(response.data)
                setCategories(response.data.data);
            }).catch(error => {
                console.log(error)
            });
        }).catch((error) => {
            setHolidaysstatus(false)
        })


    }

    const updateSubstitution = e => {
        setSubstitution_by(e.target.value)
    }

    const changeStart = e => {
        console.log('e', e);
        setStart(e.target.value)
        hitungHari()
    }
    const changeEnd = e => {
        setEnd(e.target.value)
        hitungHari()
    }

    const change_count_weekend = e => {
        if (e.target.checked) {
            setcount_weekend(2);
        } else {
            setcount_weekend(1);
        }
        const inputElement = document.getElementById('formalasan');
        inputElement.focus();
        hitungHari()

    }
    const change_count_weekend_minggu = e => {
        if (e.target.checked) {
            setcount_weekend_minggu(2);
        } else {
            setcount_weekend_minggu(1);
        }
        hitungHari()
    }
    const change_count_holidays = e => {
        if (e.target.checked) {
            setcount_holidays(2);
        } else {
            setcount_holidays(1);
        }
        hitungHari()
    }


    const hitungHari = () => {

        if (start != '' && end != '') {
            const datetime1 = new Date(start);
            const datetime2 = new Date(end);

            if (datetime1 <= datetime2) {

                setExclude([]);

                console.log('start', start);
                console.log('end', end);

                console.log('holidays', holidays);
                console.log('count_weekend', count_weekend);
                console.log('count_weekend_minggu', count_weekend_minggu);
                console.log('count_holidays', count_holidays);

                let a = moment(start);
                let b = moment(end);
                let moment_b = moment(b).format('YYYY-MM-DD');
                let working_day = parseFloat(b.diff(a, 'days')) + 1;
                let total_hari_kerja_akhir = 0;
                let hari_exclude = [];
                if (working_day > 0) {


                    for (let i = 0; i < working_day; i++) {
                        let new_moment = moment(start).add(i, 'days')
                        let date = new_moment.format('YYYY-MM-DD');
                        // console.log('date', date);

                        let hitung_hari = true;

                        // hitung hari sabtu sebagai exclude
                        if (count_weekend == 1 && new_moment.day() == 6) {
                            const newItem = { date: date, date_name: new_moment.format('DD MMM YYYY'), msg: 'Sabtu' };
                            hari_exclude.push(newItem);
                            // const newItems = [...exclude, newItem];
                            // setExclude(newItems);
                            hitung_hari = false;
                        }


                        // hitung hari minggu sebagai exclude
                        if (count_weekend_minggu == 1 && new_moment.day() == 0) {
                            const newItem = { date: date, date_name: new_moment.format('DD MMM YYYY'), msg: 'Minggu' };
                            hari_exclude.push(newItem);
                            // const newItems = [...exclude, newItem];
                            // setExclude(newItems);
                            hitung_hari = false;
                        }

                        // cek di hari libur
                        if (count_holidays == 1) {
                            for (let h = 0; h < holidays.length; h++) {
                                const el_h = holidays[h];
                                if (date == el_h.holiday_date) {
                                    const newItem = { date: date, date_name: new_moment.format('DD MMM YYYY'), msg: el_h.descriptions };
                                    hari_exclude.push(newItem);
                                    // const newItems = [...exclude, newItem];
                                    // setExclude(newItems);
                                    hitung_hari = false;
                                }
                            }
                        }

                        if (hitung_hari == true) {
                            total_hari_kerja_akhir = total_hari_kerja_akhir + 1;
                        }


                    }
                }

                setExclude(hari_exclude)

                console.log('moment_b', moment_b);
                console.log('working_day', working_day);
                console.log('exclude', exclude);
                console.log('hari_exclude', hari_exclude);
                console.log('total_hari_kerja_akhir', total_hari_kerja_akhir);

                setWorkingday(total_hari_kerja_akhir)

            } else {
                setWorkingday(0)
            }
        }


    }

    const udateSelectedFile = e => {

        let file_size = Math.round(e.target.files[0].size / (1024 ** 2));

        if (file_size > 3) {
            setSelectedFile('')
            alert('File lebih besar dari 3 Mb');
            e.target.value = null;
        } else {
            getBase64(e.target.files[0])
                .then(result => {
                    setSelectedFile(result)
                })
                .catch(err => {
                    setSelectedFile('')
                });
        }

    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                // console.log(baseURL);
                resolve(baseURL);
            };
            // console.log(fileInfo);
        });
    };

    const submitPemission = e => {
        e.preventDefault()
    }




    useEffect(() => {
        loadCategories()
    }, []);


    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={6} md={6}>
                    <h3>Form Izin</h3>
                    <hr />

                    <div style={{ display: (holidaysstatus) ? 'block' : 'none' }}>
                        <Form.Group className="mb-3">
                            <Form.Label>Kategori</Form.Label>
                            <Form.Select onChange={updateCategory} defaultValue={category}>
                                <option value="" disabled>-- Pilih Kategori --</option>
                                {categories.map((cat, index) =>
                                    <option value={cat.leave_category_id} key={cat.leave_category_id}>{cat.name}</option>
                                )}
                            </Form.Select>
                        </Form.Group>

                        {(category_desc.length > 0) ?
                            <Alert variant="info">
                                {category_desc}
                            </Alert>
                            : ''}
                        {(typeLabel.length > 0) ?
                            <Alert variant="warning">
                                {typeLabel}
                            </Alert>
                            : ''}

                        <Row className="mb-3">
                            <Col>
                                <Form.Group>
                                    <Form.Label>Start</Form.Label>
                                    <Form.Control onChange={changeStart} onBlur={hitungHari} value={start} type="datetime-local" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>End</Form.Label>
                                    <Form.Control onChange={changeEnd} onBlur={hitungHari} value={end} type="datetime-local" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Hitung Hari ?</Form.Label>



                            <div>
                                <Form.Check
                                    inline
                                    label="Sabtu"
                                    name="count_weekend"
                                    type="checkbox"
                                    id="count_weekend"
                                    onChange={change_count_weekend}
                                    onBlur={hitungHari}
                                // checked={false}
                                />
                                <Form.Check
                                    inline
                                    label="Minggu"
                                    name="count_weekend_minggu"
                                    type="checkbox"
                                    id="count_weekend_minggu"
                                    onChange={change_count_weekend_minggu}
                                    onBlur={hitungHari}
                                // checked={false}
                                />
                                <Form.Check
                                    inline
                                    label="Libur Nasional"
                                    name="count_holidays"
                                    type="checkbox"
                                    id="count_holidays"
                                    onChange={change_count_holidays}
                                    onBlur={hitungHari}
                                // checked={false}
                                />
                            </div>
                        </Form.Group>

                        {(workingday != 0)
                            ? (<div className="mb-3" style={{ padding: '10px', background: '#e7e7e7', borderRadius: '10px' }}>
                                <Button variant="secondary" className="mb-3" size="sm" onClick={hitungHari}>Hitung Ulang Jumlah Hari Izin</Button>
                                <p>Total Hari Izin : <b>{workingday}</b> hari</p>
                                {(exclude.length > 0) ? 'Hari yang tidak dihitung :' : ''}
                                <ul>
                                    {exclude.map((exc, index) =>
                                        <li>{exc.date_name} | {exc.msg}</li>
                                    )}
                                </ul>
                            </div>)
                            : ''}

                        {/* <div className="mb-3" style={{ padding: '10px', background: '#e7e7e7', borderRadius: '10px' }}>
                            <Button variant="secondary" className="mb-3" size="sm" onClick={hitungHari}>Hitung Hari Kerja</Button>
                            <p>Total Hari Izin : <b>{workingday}</b> hari</p>
                            {(exclude.length > 0) ? 'Hari yang tidak dihitung :' : ''}
                            <ul>
                                {exclude.map((exc, index) =>
                                    <li>{exc.date_name} | {exc.msg}</li>
                                )}
                            </ul>
                        </div> */}

                        {/* <Form.Group className="mb-3">
                            <Form.Label>Alasan</Form.Label>
                            <Form.Control maxLength="75" as="textarea" rows={3} />
                            <Form.Text className="text-muted">
                                Maksimal 75 karakter (0/75)
                            </Form.Text>
                        </Form.Group> */}

                        {
                            (profile.positions[0].members.length > 0) ?
                                <Form.Group className="mb-3">
                                    <Form.Label>Pengganti Selama Izin</Form.Label>
                                    <Form.Select onChange={updateSubstitution} defaultValue={substitution_by}>
                                        <option value="" disabled>-- Pilih Karyawan --</option>
                                        {profile.positions[0].members.map((mbr, index) =>
                                            <option value={mbr.employee_id} key={mbr.employee_id}>{mbr.name}</option>
                                        )}
                                    </Form.Select>
                                </Form.Group>
                                : ''
                        }

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>File (hanya file <i>image</i>)</Form.Label>
                            <Form.Control type="file" ref={inputFoto} onChange={udateSelectedFile} accept="image/*" />
                            <Form.Text className="text-muted">
                                Maksimal 3 Mb<br />
                                * Jika file lebih dari 3 Mb silahkan kompres <a href="https://compressjpeg.com/id/" target="_blank" >disini</a>
                            </Form.Text>
                        </Form.Group>

                        <Button disabled={isLoading} variant="success" onClick={submitPemission}>{isLoading ? 'Loading…' : ''}</Button>

                    </div>
                    <div style={{ display: (holidaysstatus) ? 'none' : 'block' }}>
                        Loading...
                    </div>





                </Col>
            </Row>
        </Container>
    );
};

export default Createpermission;