import './App.css';
import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer, Navbar, Container } from 'react-bootstrap';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import MainHeader from './components/MainHeader';
import { fetchToken, onMessageListener } from './firebase';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import http from "./_services/http-common";
// context
import { ProfileProvider } from './_context/ProfileContext';
// === page ===
import Force from './pages/login/Force';
import Login from './pages/login/Login';
import Kebijakanprivasi from './pages/login/Kebijakanprivasi';
import Forgotpassword from './pages/forgotpassword/Forgotpassword';
import Changepassword from './pages/forgotpassword/Changepassword';
import Profile from './pages/profile/profile';
import Attendance from './pages/attendance/Attendance';
import Permissions from './pages/permissions/Permissions';
import Listpermissions from './pages/permissions/Listpermissions';
import Leavehistory from './pages/permissions/Leavehistory';
import Updatepassword from './pages/profile/Updatepassword';
import Announcement from './pages/announcement/Announcement';
import Suggestions from './pages/profile/Suggestions';
import Changeprofile from './pages/profile/changeprofile';
import Changefotoprofile from './pages/profile/changefotoprofile';
import Historychangeprofile from './pages/profile/historychangeprofile';
import Detailchangeprofile from './pages/profile/detailchangeprofile';
import Maintenance from './pages/maintenance/Maintenance';
import Createpermission from './pages/permissions/Createpermission'
import Newpermissions from './pages/permissions/Newpermissions'

import PWAPrompt from 'react-ios-pwa-prompt'

import moment from 'moment-timezone';
import ChangeFamily from './pages/profile/changeFamily';
moment.tz.setDefault("Asia/Jakarta");


library.add(fab, fas)
// library.add()


function App() {

  let isAuth = localStorage.getItem('auth');

  http.get('ess/login/checkmaintenance').then(response => {
    // console.log('response', response.data.status);
    const currentURL = window.location.pathname
    // console.log('currentURL', currentURL)
    if (parseInt(response.data.status) == 1) {
      if (currentURL != '/maintenance') {
        localStorage.clear();
        window.location.replace('/maintenance');
      } else {
        console.log('Halaman maintenence');
      }
    }
    
  }).catch(error => {
    // console.log(error)
  });

  useEffect(() => {
    if (isAuth) {
      http.get('ess/login/checkapikey').then(response => {
        if (!response.data.status) {
          alert('Your token is expired, please relogin');
          localStorage.clear();
          window.location.replace('/')
        }
        //    setProfile(response.data.data);
      }).catch(error => {
        // console.log(error)
        alert('Sorry your token has expired, please login again if you want to access this web');
        localStorage.clear();
        window.location.replace('/')
      });
    }

  }, []);

  let routes = (<Routes>
    <Route path='*' element={<Navigate replace to="/login" />} />
    <Route path='/' element={<Navigate replace to="/login" />} />
    <Route path='/login' exact element={<Login />} />
    <Route path='/kebijakanprivasi' exact element={<Kebijakanprivasi />} />
    <Route path='/forgotpassword' element={<Forgotpassword />} />
    <Route path='/changepassword' element={<Changepassword />} />
    <Route path='/force' exact element={<Force />} />
    <Route path='/maintenance' exact element={<Maintenance />} />
  </Routes>);



  if (isAuth) {
    routes = (<Routes>
      <Route path='*' element={<Navigate replace to="/Profile" />} />
      <Route path='/' element={<Navigate replace to="/Profile" />} />
      <Route path='/profile' element={<Profile />} />
      <Route path='/attendance' element={<Attendance />} />
      <Route path='/permissions' element={<Permissions />} />
      <Route path='/createpermission' element={<Createpermission />} />
      <Route path='/newpermissions' element={<Newpermissions />} />
      <Route path='/listpermissions' element={<Listpermissions />} />
      <Route path='/leavehistory' element={<Leavehistory />} />
      <Route path='/updatepassword' element={<Updatepassword />} />
      <Route path='/changeprofile' element={<Changeprofile />} />
      <Route path='/changefamily' element={<ChangeFamily />} />
      <Route path='/Changefotoprofile' element={<Changefotoprofile />} />
      <Route path='/historychangeprofile' element={<Historychangeprofile />} />
      <Route path='/detailchangeprofile/:id' element={<Detailchangeprofile />} />
      <Route path='/announcement' element={<Announcement />} />
      <Route path='/suggestions' element={<Suggestions />} />
    </Routes>);
  }

  return (

    <div>
      <ProfileProvider>

        <Helmet>
          <title>ESS - Rimau Group</title>
          <link rel="canonical" href="http://mysite.com/example" />
          <meta name="description" content="Nested component" />
          <meta name="google" content="notranslate" />
          <meta http-equiv='cache-control' content='no-cache' />
          <meta http-equiv='expires' content='0' />
          <meta http-equiv='pragma' content='no-cache' />
          <style>{'body { font-family: "MavenPro"; background-image: url("AppImages/brand/bg-body3.jpg"); background-color: #cccccc; padding-top:50px; }'}</style>
          <link rel="stylesheet" href="https://cdn.datatables.net/1.12.0/css/dataTables.bootstrap5.min.css" />
        </Helmet>
        <MainHeader />
        <PWAPrompt />
        <main>
          {routes}
          <Navbar>
            <Container>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  © Rimau Group 2022. right Reserved
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </main>
      </ProfileProvider>
    </div>
  );
}

export default App;
