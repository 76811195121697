import React, { useState, useEffect } from 'react';
import http from "../../_services/http-common";
import { Card, Container, Button, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from "react-helmet";

const Forgotpassword = () => {

    const [isLoading, setLoading] = useState(false);
    const [isSubmit, setSubmit] = useState(false);



    const [email, setEmail] = useState('')

    const updateEmail = e => {
        setEmail(e.target.value)
    }

    const submit = e => {
        e.preventDefault()

        if (email != '') {
            setLoading(true)
            const params = new URLSearchParams()
            params.append('email', email)
            http.post('ess/login/requestresetpassword', params).then(response => {

                if (response.data.status) {
                    toast.success('Email terkirim')
                    setSubmit(true)
                } else {
                    toast.error('Email tidak terkirim')
                }
                setLoading(false)

            }).catch(error => {
                toast.error('Email tidak terkirim')
                setLoading(false)
            })
        }


    }

    return (<Container>
        <Helmet>
            <style>{'body { padding-top:0px; }'}</style>
        </Helmet>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ background: '#f5f5f5' }}>
                        <Card.Img variant="top" src="AppImages/brand/logo3.png" />
                        <Card.Body>
                            <Card.Title className="mb-4">- Permintaan Ubah Password</Card.Title>

                            {(!isSubmit)
                                ? <>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3"
                                    >
                                        <Form.Control placeholder='Email' type="email" value={email} onChange={updateEmail} />
                                    </FloatingLabel>
                                    <div className="mt-4">
                                        <Button href="/login" variant="outline-secondary"><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" />&nbsp; Login</Button>
                                        <Button className="float-end" variant="primary" disabled={isLoading} onClick={submit}>
                                            {isLoading ? 'Loading…' : 'Kirim'}
                                        </Button>
                                    </div>
                                    <div className="mt-4" style={{ background: '#eaeaea', padding: '10px', color: '#607d8b' }}>
                                        <ul>
                                            <li>Pastikan email Anda terdaftar sebagai email karyawan akun Rimau Anda.</li>
                                            <li>Jika email tidak terkirim silahkan hubungi staf HR untuk membantu Anda merubah Password.</li>
                                        </ul>
                                    </div>
                                </>
                                : <div className="mt-4" style={{ background: '#eaeaea', padding: '10px', color: '#607d8b' }}>
                                    <ul>
                                        <li>Kami telah mengirim email tautan untuk merubah Password ke email <b>{email}</b>, silahkan cek <b>kotak masuk</b> atau <b>kotak spam</b> email Anda</li>
                                        <li>Jika Anda tidak menerima email silahkan hubungi staf HR untuk membantu merubah Password Anda.</li>
                                    </ul>
                                </div>}

                        </Card.Body>
                    </Card>
                </div>


            </Col>
        </Row>
    </Container>)
};

export default Forgotpassword;