import React, { useState, useEffect, useContext, useRef } from 'react';
import { Table, Container, Modal, Button, Form, Alert, Row, Col, Image } from "react-bootstrap";
import http from "../../_services/http-common";
import { ProfileContext } from '../../_context/ProfileContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';

import { toast } from 'react-toastify';

const Permissions = () => {
    const inputFoto = useRef()
    const last_year = moment().subtract(1, 'years').format('yyyy');
    const [profile, setProfile] = useContext(ProfileContext);

    const [isLoading, setLoading] = useState(false);
    const [isLoadingWH, setLoadingWH] = useState(false);
    const [show, setShow] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [viewTitle, setViewTitle] = useState('');
    const [viewFiles, setViewFiles] = useState('');

    const [firstLoading, setFirstLoading] = useState(true);
    const [isUpdateEnd, setIsUpdateEnd] = useState(true);
    const [minDateEnd, setMinDateEnd] = useState('');


    const [permissions, setPermissions] = useState([]);
    const [categories, setCategories] = useState([]);

    const [holidays, setHolidays] = useState([]);

    const [loadingModal, setLoadingModal] = useState(false);
    const [allowformpermission, setAllowformpermission] = useState(0);

    const [category, setCategory] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [workingday, setWorkingday] = useState('');
    const [reason, setReason] = useState('');
    const [substitution_by, setSubstitution_by] = useState('');
    const [type, setType] = useState('');
    const [typeLabel, setTypeLabel] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);

    const [category_desc, setDesc] = useState('');

    const [isChecked, setIsChecked] = useState(false);

    const [view_sisa_cuti, setCutiBefore] = useState('');

    const handleCloseFile = () => {
        setShowFile(false)
    }

    const handleShowFile = (filename, title) => {
        setShowFile(true)
        filename = 'https://api.rimauapk.com/uploads/permissions/' + filename;
        setViewFiles(filename)
        setViewTitle(title)
    };

    const handleClose = () => {
        setLoadingModal(false)
        setShow(false)
    }
    const handleShow = () => {
        setTimeout(() => {
            setLoadingModal(false)
        }, 3000)
        setLoadingModal(true)
        loadCategories()
        setShow(true)

    };

    const updateSubstitution = e => {
        setSubstitution_by(e.target.value)
    }

    const updateCategory = e => {
        setCategory(e.target.value)
        setDesc('')
        setType('')
        setTypeLabel('Tidak memotong cuti / gaji')
        for (let i = 0; i < categories.length; i++) {
            const d = categories[i];
            if (d.leave_category_id == e.target.value) {
                console.log(d.is_cuti);
                setDesc(d.descriptions)
                if (parseInt(d.is_cuti) == 1 && parseInt(profile.cuti) > 0) {
                    setType('1')
                    setTypeLabel('Akan memotong cuti (Sisa cuti saat ini : ' + profile.cuti + ')')
                } else if (parseInt(d.is_cuti) == 1 && parseInt(profile.cuti) <= 0) {
                    setType('2')
                    setTypeLabel('Saat ini Anda tidak memiliki sisa cuti, sehingga akan memotong gaji Anda')
                }
                console.log(type)
            }

        }
    };
    const updateStart = e => {
        setStart(e.target.value)
        setIsUpdateEnd(false)
        setMinDateEnd(e.target.value)
        setEnd('')
        setWorkingday('')
        setHolidays([])
        // updateWorkingday(e.target.value, end)
    };
    const updateEnd = e => {
        setEnd(e.target.value)
        updateWorkingday(start, e.target.value)
    };

    const updateReason = e => {
        setReason(e.target.value)
    };
    const updateType = e => {
        setType(e.target.value)
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                // console.log(baseURL);
                resolve(baseURL);
            };
            // console.log(fileInfo);
        });
    };

    const udateSelectedFile = e => {

        let file_size = Math.round(e.target.files[0].size / (1024 ** 2));

        if (file_size > 3) {
            setSelectedFile('')
            alert('File lebih besar dari 3 Mb');
            e.target.value = null;
        } else {
            getBase64(e.target.files[0])
                .then(result => {
                    setSelectedFile(result)
                })
                .catch(err => {
                    setSelectedFile('')
                });
        }

    }

    const submitPemission = e => {
        e.preventDefault()

        // console.log('profile',profile);
        let checkUpload = true
        if (profile.group_id == 6) {
            checkUpload = (inputFoto.current.value) ? true : false;
        }

        if (category &&
            start &&
            end &&
            workingday &&
            reason &&
            checkUpload) {

            // console.log(selectedFile);

            let checkCuti = true;
            let use_cuti_before = parseInt(profile.use_cuti_before);
            let sisaCuti = parseInt(profile.cuti);
            let sisaCutiBefore = parseInt(profile.cuti_before);
            sisaCuti = (use_cuti_before == 1) ? sisaCuti + sisaCutiBefore : sisaCuti;

            if (parseInt(type) == 1 && workingday > sisaCuti) {
                checkCuti = false;
            }

            // console.log(type, workingday, sisaCuti, checkCuti);

            if (workingday > 0 && checkCuti) {
                let superior_status = 0; // (parseInt(profile.count_manager_id) > 0) ? 1 : 0;

                const params = new URLSearchParams()
                params.append('leave_category_id', category)
                params.append('start_date', start)
                params.append('end_date', end)
                params.append('working_day', workingday)
                params.append('reason', reason)
                // params.append('substitution_by', reason)
                params.append('type', type)
                params.append('superior_status', superior_status)
                params.append('substitution_by', substitution_by)
                // params.append('file', (isChecked) ? selectedFile : '')
                params.append('file', selectedFile)

                console.log('params', substitution_by);

                // return false;

                setLoading(true);



                http.put('ess/attendance/permission', params).then(response => {
                    setLoading(false)
                    if (response.data.status) {
                        loadPermissions()
                        toast.success("Permintaan izin terkirim")
                        setShow(false)
                    } else {
                        toast.warn("Tanggal izin bentrok dengan izin lain")
                    }
                }).catch(error => {
                    toast.error("Tejadi kesalahan, cobalah beberapa saat lagi")
                    console.log(error);
                    setLoading(false);
                })

            } else {
                if (!checkCuti) {
                    toast.warn("Jumlah hari kerja harus lebih kecil atau sama dengan dari sisa cuti Anda")
                } else if (workingday <= 0) {
                    toast.warn("Jumlah hari kerja harus lebih besar dari 0")
                }

            }

        } else {
            toast.warn("Mohon isi semua form")
        }

    }


    async function updateWorkingday(start, end) {

        console.log('start', start);
        console.log('end', end);

        if (start != '' && start != null && end != '' && end != null) {
            setHolidays([])
            setLoadingWH(true)
            setLoading(true)
            setWorkingday('')

            let a = moment(start);
            let b = moment(end);
            let moment_b = moment(b).format('YYYY-MM-DD');
            let working_day = parseFloat(b.diff(a, 'days')) + 1;
            console.log('working_day', working_day);
            if (working_day > 0) {
                let exclude = 0
                if (profile.cuti_type == 'Tahunan') {
                    for (let i = 0; i < working_day; i++) {
                        let date = moment(start).add(i, 'days').format('YYYY-MM-DD');

                        // console.log(date + ' data_holiday', data_holiday)
                        console.log('moment_b', moment_b)
                        if (date == moment_b) {
                            break;
                        }
                        if (moment(date).isAfter(moment_b)) {
                            break;
                        } else {

                            let data_holiday = await isholiday(date)
                            if (data_holiday.status) {
                                exclude = exclude + 1;
                                setHolidays(holidays => [...holidays, data_holiday])
                            }
                        }

                    }
                }

                // console.log(holidays)
                let view_exclude = working_day - exclude;
                let view_working_day = (view_exclude > 0) ? view_exclude : 0;
                setWorkingday(view_working_day)
                setLoadingWH(false)
                setLoading(false)
            } else {
                setWorkingday('')
                setEnd('')
                toast.warn("Tanggal akhir harus lebih besar dari tanggal mulai")
                setLoadingWH(false)
                setLoading(false)
            }
        }

    }

    function isholiday(date) {
        return new Promise((resolve, reject) => {

            http.get('ess/attendance/isholiday', { params: { date: date } }).then(response => {
                // console.log(response.data)
                resolve(response.data)

            }).catch(error => {
                reject(error)
            });

        });
    }

    function loadPermissions() {
        http.get('ess/attendance/permissionlist').then(response => {
            // console.log(response.data)
            setFirstLoading(false)
            checkAllowingPermission()
            setPermissions(response.data.data);

        }).catch(error => {
            console.log(error)
        });

    }

    // function loadProfile() {

    //     http.get('ess/profile/detail').then(response => {
    //         console.log(response.data.data)
    //     }).catch(error => {
    //         console.log(error)
    //     });

    // }

    function loadCategories() {
        http.get('ess/attendance/leavecategory').then(response => {
            // console.log(response.data)
            setCategories(response.data.data);
        }).catch(error => {
            console.log(error)
        });
    }

    function checkAllowingPermission() {
        http.get('ess/attendance/checkpermission').then(response => {
            // console.log(response.data)
            setLoadingModal(false)
            // let allowing = (parseInt(response.data.total_data) > 0) ? false : true;
            let allowing = (parseInt(response.data.total_data) > 0) ? 1 : 2;
            setAllowformpermission(allowing);
        }).catch(error => {
            console.log(error)
        });
    }


    useEffect(() => {
        console.log('profile', profile);
        if (parseInt(profile.use_cuti_before) == 1) {
            setCutiBefore(' | Sisa Cuti ' + last_year + ' : ' + profile.cuti_before);
        }
        loadPermissions()
    }, []);

    return (
        <Container className="mt-5">
            <Row className="mt-4 justify-content-md-center">
                <Col xs={12} sm={12} md={12}>

                    <>
                        <Button variant="outline-primary" href='/leavehistory'>
                            Sisa Cuti : {profile.cuti}{view_sisa_cuti}
                        </Button> &nbsp;
                        {/* <Button variant="primary" onClick={handleShow}>
                            Buat Izin Old
                        </Button> */}
                        {(allowformpermission == 2) ? (
                            <Button variant="primary" href='/newpermissions'>
                                Buat Izin
                            </Button>
                        ) :
                            (allowformpermission == 1) ? (
                                <div>
                                    <br />
                                    <div role="alert" class="fade alert alert-info show">Anda mempunyai permintaan izin <b>potong cuti</b> yang belum diberi tanggapan, sehingga tidak dapat membuat izin lain samapai izin tersebut disetujui / ditolak.</div>
                                </div>
                            ) : ''
                        }


                        <Modal
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Buat Izin</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {(allowformpermission) ?
                                    <Form>

                                        <Form.Group className="mb-3" controlId="category">
                                            <Form.Label>Kategori</Form.Label>
                                            <Form.Select onChange={updateCategory} defaultValue={category}>
                                                <option value="" disabled>-- Pilih Kategori --</option>
                                                {categories.map((cat, index) =>
                                                    <option value={cat.leave_category_id} key={cat.leave_category_id}>{cat.name}</option>
                                                )}
                                            </Form.Select>

                                        </Form.Group>
                                        {(category_desc.length > 0) ?
                                            <Alert variant="info">
                                                {category_desc}
                                            </Alert>
                                            : ''}
                                        {(typeLabel.length > 0) ?
                                            <Alert variant="warning">
                                                {typeLabel}
                                            </Alert>
                                            : ''}
                                        <Form.Group className="mb-3" controlId="start">
                                            <Form.Label>Start</Form.Label>
                                            <Form.Control onChange={updateStart} value={start} type="datetime-local" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="end">
                                            <Form.Label>End</Form.Label>
                                            <Form.Control onChange={updateEnd} value={end}
                                                min={minDateEnd}
                                                type="datetime-local" disabled={isUpdateEnd} />
                                        </Form.Group>

                                        {/* style={{ display: 'none' }} */}
                                        <Form.Group className="mb-3" controlId="wh">
                                            <Form.Label>Hari Kerja </Form.Label>
                                            <Form.Control type="number" value={workingday} readOnly />

                                            {(isLoadingWH) ? <Form.Text className="text-muted">Menghitung hari kerja...</Form.Text> : ''}

                                            {(holidays.length > 0)
                                                ?
                                                <Form.Text className="text-muted">
                                                    <ul>
                                                        {holidays.map((holiday, index) =>
                                                            <li key={index}> <Moment format="DD MMM YYYY" withTitle>
                                                                {holiday.date}
                                                            </Moment> | {holiday.message}</li>
                                                        )}
                                                    </ul>
                                                </Form.Text>

                                                : ''}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="reason">
                                            <Form.Label>Alasan</Form.Label>
                                            <Form.Control onChange={updateReason} maxLength="75" value={reason} as="textarea" rows={3} />
                                            <Form.Text className="text-muted">
                                                Maksimal 75 karakter ({reason.length}/75)
                                            </Form.Text>
                                        </Form.Group>

                                        {/* <hr /> */}

                                        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                            <Form.Check type="checkbox" checked={isChecked}
                                                onChange={handleOnChange} label="Tambah Lampiran File" />
                                            <Form.Text className="text-muted">
                                                Tidak wajib diisi
                                            </Form.Text>
                                        </Form.Group> */}

                                        {
                                            (profile.positions[0].members.length > 0) ?
                                                <Form.Group className="mb-3" controlId="category">
                                                    <Form.Label>Pengganti Selama Izin</Form.Label>
                                                    <Form.Select onChange={updateSubstitution} defaultValue={substitution_by}>
                                                        <option value="" disabled>-- Pilih Karyawan --</option>
                                                        {profile.positions[0].members.map((mbr, index) =>
                                                            <option value={mbr.employee_id} key={mbr.employee_id}>{mbr.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                                : ''
                                        }

                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>File (hanya file <i>image</i>)</Form.Label>
                                            <Form.Control type="file" ref={inputFoto} onChange={udateSelectedFile} accept="image/*" />
                                            <Form.Text className="text-muted">
                                                Maksimal 3 Mb<br />
                                                * Jika file lebih dari 3 Mb silahkan kompres <a href="https://compressjpeg.com/id/" target="_blank" >disini</a>
                                            </Form.Text>
                                        </Form.Group>




                                    </Form>
                                    : <div style={{ textAlign: 'center', color: '#607d8b' }}>
                                        {loadingModal}
                                        {(loadingModal) ? <h5>Loading...</h5> : <h5>Anda memiliki permintaan izin yang belum ditanggapi, harap tunggu hingga permintaan izin Anda dijawab</h5>}
                                    </div>}

                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Tutup
                                </Button>
                                {(allowformpermission) ? <Button disabled={isLoading} variant="success" onClick={submitPemission}>{isLoading ? 'Loading…' : 'Kirim'}</Button> : ''}

                            </Modal.Footer>
                        </Modal>


                        <Modal
                            show={showFile}
                            onHide={handleCloseFile}
                            backdrop="static"
                            keyboard={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{viewTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Image className="rounded mx-auto d-block" src={viewFiles} style={{ width: '100%', maxWidth: '1200px' }} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseFile}>
                                    Tutup
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>

                    <h3 className='mt-5'>List Permission</h3>
                    <hr />

                    {(permissions.length > 0) ? <>

                        <Table responsive striped bordered gap={3} className='mt-1' style={{ background: '#ffffff' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '1%' }}>#</th>
                                    <th>Kategori</th>
                                    <th style={{ width: '5%' }}>Tipe</th>
                                    <th>Alasan</th>
                                    <th style={{ width: '13%' }}>Start</th>
                                    <th style={{ width: '13%' }}>End</th>
                                    <th style={{ width: '1%' }}>Hari Kerja</th>
                                    <th style={{ width: '13%' }}>Sup. Status</th>
                                    <th style={{ width: '13%' }}>HR Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permissions.map((permission, index) =>
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            {permission.leave_category_name}
                                            {(permission.file != '' && permission.file != null && permission.file != 'null')
                                                ? <div><Button variant="link" onClick={() => { handleShowFile(permission.file, permission.leave_category_name) }} size="sm">Lihat Lampiran File</Button></div>
                                                : ''}

                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{
                                            (permission.type == '1') ? <span>Potong Cuti</span> :
                                                (permission.type == '2') ? <span>Potong Gaji</span>
                                                    : <span>Tidak Potong Cuti / Gaji</span>}</td>
                                        <td>{permission.reason}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            <Moment format="DD MMM YYYY HH:mm" withTitle>
                                                {permission.start_date}
                                            </Moment>
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            <Moment format="DD MMM YYYY HH:mm" withTitle>
                                                {permission.end_date}
                                            </Moment>
                                        </td>
                                        <td style={{ textAlign: 'center' }}>
                                            {permission.working_day}
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{(permission.superior_status == '0')
                                            ? <span style={{ color: 'blue' }}>Menuggu tanggapan</span>
                                            : (permission.superior_status == '1')
                                                ? <>
                                                    <span style={{ color: 'green' }}><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Disetujui</span>
                                                    <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.superior_action_name}</div>
                                                </>
                                                : <span style={{ color: 'red' }}><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Tidak disetujui</span>
                                        }</td>

                                        <td style={{ whiteSpace: 'nowrap' }}>{(permission.hr_status == '0')
                                            ? <span style={{ color: 'blue' }}>Menuggu tanggapan</span>
                                            : (permission.hr_status == '1')
                                                ? <>
                                                    <span style={{ color: 'green' }}><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Disetujui</span>
                                                    <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.hr_action_name}</div>
                                                </>
                                                : <span style={{ color: 'red' }}><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Tidak disetujui</span>
                                        }</td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </> :

                        <Row className="mt-5 justify-content-md-center" style={{ background: '#eaeaea', padding: '10px', color: '#607d8b' }}>
                            <Col xs={12} sm={4} md={4}>
                                <h3 style={{ textAlign: 'center' }}>{(firstLoading) ? 'Loading...' : 'Tidak ada riwayat izin.'}</h3>
                            </Col>
                        </Row>
                    }

                </Col>
            </Row>
        </Container>

    );
};

export default Permissions;