import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Modal, Button, Badge, Alert, Row, Col, Image } from "react-bootstrap";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';

const Listpermissions = () => {

  const [loadingPage, setLoadingPage] = useState(true);

  const [disabledbutton, setDisabledbutton] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);



  const [modelTitle, setModelTitle] = useState('');

  const [m_leave_id, setM_leave_id] = useState('');
  const [m_kategori, setM_kategori] = useState('');
  const [m_start, setM_start] = useState('');
  const [m_end, setM_end] = useState('');
  const [m_wh, setM_wh] = useState('');
  const [m_reason, setM_reason] = useState('');
  const [m_tipe, setM_tipe] = useState('');
  const [m_hr_status, setM_hr_status] = useState('0');

  const [permissions, setPermissions] = useState([]);


  const [showFile, setShowFile] = useState(false);
  const [viewTitle, setViewTitle] = useState('');
  const [viewFiles, setViewFiles] = useState('');


  const handleCloseFile = () => {
    setShowFile(false)
  }

  const handleShowFile = (filename, title) => {
    setShowFile(true)
    filename = 'https://api.rimauapk.com/uploads/permissions/' + filename;
    setViewFiles(filename)
    setViewTitle(title)
  };

  function loadPermissions() {
    http.get('ess/attendance/memberpermission').then(response => {
      // console.log(response.data.data)
      // checkAllowingPermission()
      setPermissions(response.data.data);
      setLoadingPage(false)

    }).catch(error => {
      console.log(error)
      setLoadingPage(false)
    });
  }

  useEffect(() => {
    loadPermissions()
  }, []);

  const showPermission = useCallback(
    (leave_id) => () => {

      http.get('ess/attendance/memberpermissiondetail', { params: { leave_id: leave_id } }).then(response => {

        if (response.data.status) {
          // console.log(index, permissions)
          let d = response.data.data;
          setModelTitle(d.nik + ' - ' + d.employee_name)

          setM_leave_id(leave_id)
          setM_kategori(d.leave_category_name)
          setM_start(moment(d.start_date).format('DD MMM YYYY HH:mm'))
          setM_end(moment(d.end_date).format('DD MMM YYYY HH:mm'));
          setM_wh(d.working_day)
          setM_reason(d.reason)
          setM_hr_status(d.hr_status)

          let d_m_tipe = (d.type == '1')
            ? 'Potong Cuti'
            : (d.type == '2') ? 'Potong Gaji' : 'Tidak Potong Cuti / Gaji';
          setM_tipe(d_m_tipe)

          setShow(true)

        } else {
          toast.error('Gagal mengambil data, silahkan coba lagi')
        }

      }).catch(error => {
        console.log(error)
        setLoadingPage(false)
      });

    },
    [],
  )

  const submitPermission = useCallback(
    (leave_id, status) => () => {

      setDisabledbutton(true)

      console.log(leave_id, status);

      const params = new URLSearchParams()
      params.append('leave_id', leave_id)
      params.append('status', status)

      http.post('ess/attendance/memberpermissionaction', params).then(response => {
        console.log(response.data)
        if (response.data.status) {
          loadPermissions()
          setShow(false)
        } else {
          toast.warn('Gagal, silahkan coba lagi')
        }

        setDisabledbutton(false)
        // if (response.data.status) {
        //   setShow(true)
        //   setToken(searchParams.get('t'))
        // }
      }).catch(error => {
        setDisabledbutton(false)
        toast.error('Terjadi kesalahan jaringan, silahkan coba beberapa saat lagi atau pastikan koneksi internet Anda stabil')
      });



    },
    [],
  )

  return (
    <Container className="mt-5">

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modelTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped gap={3} className='mt-1' style={{ background: '#ffffff' }}>
            <tbody>
              <tr>
                <td style={{ width: '25%' }}>Kategori</td>
                <td style={{ width: '1%' }}>:</td>
                <td>{m_kategori}</td>
              </tr>
              <tr>
                <td>Start</td>
                <td>:</td>
                <td>{m_start}</td>
              </tr>
              <tr>
                <td>End</td>
                <td>:</td>
                <td>{m_end}</td>
              </tr>
              <tr>
                <td>Hari Kerja</td>
                <td>:</td>
                <td>{m_wh}</td>
              </tr>
              <tr>
                <td>Alasan</td>
                <td>:</td>
                <td>{m_reason}</td>
              </tr>
              <tr>
                <td>Tipe</td>
                <td>:</td>
                <td>{m_tipe}</td>
              </tr>
            </tbody>
          </Table>
          {/* <Alert variant="warning">
            Perhatian : Pembatalan cuti hanya bisa dilakukan lewat 
          </Alert> */}
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
          {(parseInt(m_hr_status) < 1) ? <Button disabled={disabledbutton} variant="outline-danger" onClick={submitPermission(m_leave_id, 2)}>
            <FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> &nbsp; Tolak
          </Button> : ''}


          <Button disabled={disabledbutton} variant="outline-success" onClick={submitPermission(m_leave_id, 1)}>
            <FontAwesomeIcon icon="fa-solid fa-circle-check" /> &nbsp; Setuju
          </Button>

          <Button disabled={disabledbutton} variant="secondary" onClick={handleClose}>
            Tutup
          </Button>

        </Modal.Footer>
      </Modal>

      <Modal
        show={showFile}
        onHide={handleCloseFile}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{viewTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image className="rounded mx-auto d-block" src={viewFiles} style={{ width: '100%', maxWidth: '1200px' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseFile}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>

      <h3>Perizinan Team
        {/* <Button className="float-end" variant="primary">Menunggu Persetujuan <Badge bg="danger">9 orang</Badge></Button> */}
      </h3>

      <hr />
      {(loadingPage) ? 'Loading data...' :
        <Table responsive striped bordered gap={3} className='mt-1' style={{ background: '#ffffff' }}>
          <thead>
            <tr>
              <th style={{ width: '1%' }}>#</th>
              <th style={{ width: '10%' }}>Nama</th>
              <th>Kategori</th>
              <th style={{ width: '5%' }}>Tipe</th>
              <th>Alasan</th>
              <th style={{ width: '10%' }}>Start</th>
              <th style={{ width: '10%' }}>End</th>
              <th style={{ width: '1%' }}>Hari Kerja</th>
              <th style={{ width: '13%' }}>Status Superior</th>
              <th style={{ width: '13%' }}>HR Status</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission, index) =>
              <tr key={index}>
                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{permission.employee_name}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {permission.leave_category_name}
                  {(permission.file != '' && permission.file != null && permission.file != 'null')
                    ? <div><Button variant="link" onClick={() => { handleShowFile(permission.file, permission.employee_name + ' - ' + permission.leave_category_name) }} size="sm">Lihat Lampiran File</Button></div>
                    : ''}
                </td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  {
                    (permission.type == '1') ? <span>Potong Cuti</span> :
                      (permission.type == '2') ? <span>Potong Gaji</span>
                        : <span>Tidak Potong Cuti / Gaji</span>}</td>
                <td>{permission.reason}</td>
                <td>
                  <Moment format="DD MMM YYYY HH:mm" withTitle>
                    {permission.start_date}
                  </Moment>
                </td>
                <td>
                  <Moment format="DD MMM YYYY HH:mm" withTitle>
                    {permission.end_date}
                  </Moment>
                </td>
                <td style={{ textAlign: 'center' }}>{permission.working_day}</td>

                <td style={{ whiteSpace: 'nowrap' }}>{
                  (permission.superior_status == '0') ?
                    (permission.hr_status == '2') ? '-' : <>
                      <span style={{ color: 'blue' }}>Menuggu tanggapan</span>
                      <div>
                        <Button variant="outline-secondary" onClick={showPermission(permission.leave_id)} size="sm">Tanggapi Izin</Button>
                      </div>
                    </>

                    : (permission.superior_status == '1') ? <><span style={{ color: 'green' }}><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Disetujui</span>
                      <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.superior_action_name}</div></>
                      : <>
                        <span style={{ color: 'red' }}><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Tidak disetujui</span>
                        <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.superior_action_name}</div>
                      </>
                }</td>

                <td style={{ whiteSpace: 'nowrap' }}>{(permission.hr_status == '0')
                  ? <span style={{ color: 'blue' }}>Menuggu tanggapan</span>
                  : (permission.hr_status == '1') ? <><span style={{ color: 'green' }}><FontAwesomeIcon icon="fa-solid fa-circle-check" /> Disetujui</span>
                    <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.hr_action_name}</div></>
                    : <>
                      <span style={{ color: 'red' }}><FontAwesomeIcon icon="fa-solid fa-circle-xmark" /> Tidak disetujui</span>
                      <div style={{ fontSize: '14px', color: '#9e9e9e' }}>By : {permission.hr_action_name}</div>
                    </>
                }</td>
              </tr>
            )}
          </tbody>
        </Table>

      }


    </Container>
  );
};

export default Listpermissions;