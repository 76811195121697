import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Card, Button, Modal, FloatingLabel, Row, Col } from "react-bootstrap";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';

const Announcement = () => {


    const [announcements, setAnnouncements] = useState([]);
    const [modelTitle, setModelTitle] = useState('');
    const [modelBody, setModelBody] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const [firstLoading, setFirstLoading] = useState(true);

    const handleClose = () => setShow(false);
    // const handleShow = () => {
    //     setShow(true)
    // };

    const handleClick = useCallback(
        (id) => () => {

            http.get('ess/profile/announcementdetail', { params: { announcement_id: id } }).then(response => {

                let d = response.data.data
                setModelTitle(d.title)
                setModelBody(d.descriptions)
                setShow(true)

            }).catch(error => {
                console.log(error)
            });


        },
        [],
    )

    // function handleShow(data_announcement) {
    //     console.log(data_announcement)
    //     setShow(true)
    // }


    function loadAnnouncement() {
        http.get('ess/profile/announcement').then(response => {
            setFirstLoading(false);
            setAnnouncements(response.data.data);
            console.log(announcements)
        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        loadAnnouncement()
    }, []);


    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={6} md={6}>
                    <h3>Pengumuman</h3>
                    <hr />

                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{modelTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <div>{modelBody}</div> */}
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: modelBody
                                }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Tutup
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {(announcements.length > 0)
                        ?
                        announcements.map((announcement, index) =>
                            <Card style={{ cursor: 'pointer' }} key={index} onClick={handleClick(announcement.announcement_id)}>
                                <Card.Body>
                                    <Card.Title>{announcement.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        <Moment format="DD MMM YYYY HH:mm" withTitle>
                                            {announcement.start_date}
                                        </Moment> -
                                        <Moment format="DD MMM YYYY HH:mm" withTitle>
                                            {announcement.end_date}
                                        </Moment></Card.Subtitle>
                                </Card.Body>
                            </Card>
                        )

                        : (firstLoading) ? 'Loading...' : 'Tidak ada pengumuman'}


                </Col>
            </Row>
        </Container>
    );
};

export default Announcement;