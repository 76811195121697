import React, { useState, useEffect, useContext } from 'react';
import {
    Table, Container, Modal, Button, Form,
    FloatingLabel, Row, Col,
    Alert, Image
} from "react-bootstrap";
import http from "../../_services/http-common";
import http2 from "../../_services/http-common-ems";
// import { ProfileContext } from '../../_context/ProfileContext';

import Moment from 'react-moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';

import Select from 'react-select'

const Changefotoprofile = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                // console.log(baseURL);
                resolve(baseURL);
            };
            // console.log(fileInfo);
        });
    };


    const udateSelectedFile = e => {

        let file_size = Math.round(e.target.files[0].size / (1024 ** 2));

        if (file_size > 3) {
            setSelectedFile('')
            alert('File lebih besar dari 3 Mb');
            e.target.value = null;
        } else {
            getBase64(e.target.files[0])
                .then(result => {
                    setSelectedFile(result)
                })
                .catch(err => {
                    setSelectedFile('')
                });
        }

    }

    useEffect(() => {
        setSelectedFile('')
        setLoading(false)
    }, []);

    const submitPhoto = e => {
        e.preventDefault()

        if (selectedFile != '' && selectedFile != null && selectedFile != 'null') {
            const params = new URLSearchParams()
            params.append('file', selectedFile)
            setLoading(true);

            http2.post('api/changephotoprofile', params).then(response => {

                setLoading(false)

                if(response.data.status){
                    toast.success("Foto berhasil diubah")
                    setTimeout(() => {
                        window.location.replace('profile')
                    }, 1500);
                } else {
                    toast.warning("Tejadi kesalahan, cobalah beberapa saat lagi")
                }

            }).catch(error => {
                toast.error("Tejadi kesalahan, cobalah beberapa saat lagi")
                setLoading(false);
            })
        }
    }

    return (<Container className="mt-5">
        <Row className="justify-content-md-center">
            <Col xs={12} sm={6} md={6}>

                <h3>Ubah Foto Profil</h3>
                <span>Perubahan foto ini tidak akan merubah foto dimesin absensi.</span>
                <hr />


                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>File (hanya file <i>image</i>)</Form.Label>
                    <Form.Control type="file" onChange={udateSelectedFile} accept="image/*" />
                    <Form.Text className="text-muted">
                        Maksimal 3 Mb<br />
                        * Jika file lebih dari 3 Mb silahkan kompres <a href="https://compressjpeg.com/id/" target="_blank" >disini</a>
                    </Form.Text>
                </Form.Group>

                <div style={{ marginTop: '10px' }}>
                    {(selectedFile != '' && selectedFile != null)
                        ? <>
                            <Image className="rounded mx-auto d-block" src={selectedFile} style={{ width: '100%', maxWidth: '300px' }} />
                            <hr />
                            <Button onClick={submitPhoto} style={{ float: 'right' }} disabled={isLoading} className="justify-content-md-center" variant="success">
                                {(isLoading) ? 'Loading...' : 'Simpan'}
                            </Button>
                        </>
                        : ''}
                </div>

            </Col>
        </Row>
    </Container>)
}

export default Changefotoprofile;