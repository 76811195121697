import React, { useState, useEffect, useContext } from 'react';
import { Table, Container, Stack, Button, Form, FloatingLabel, Row, Col, Badge, Modal } from "react-bootstrap";
import http from "../../_services/http-common";

import { ProfileContext } from '../../_context/ProfileContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';


const Attendance = () => {

    const [profile, setProfile] = useContext(ProfileContext);

    const current_id_month = 0;


    const [currentYear, setCurrentYear] = useState(0);
    const [currentid, setCurrentid] = useState(0);
    const [monthlist, setMonthlist] = useState([]);

    const [dataattendance, setDataattendance] = useState([]);
    const [dataliburnasional, setDataliburnasional] = useState([]);
    const [datapermission, setDatapermission] = useState([]);
    const [datashift, setDatashift] = useState([]);
    const [baseshiftstart, setBaseshiftstart] = useState('');
    const [baseshiftend, setBaseshiftend] = useState('');

    const [holidaythisyear, setHolidaythisyear] = useState([]);

    const [viewDate, setViewdate] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const updateMonth = e => {
        view_tanggal(monthlist, e.target.value);
    }

    const refrshtabel = e => {
        view_tanggal(monthlist, currentid);
    }

    function view_tanggal(monthlist_loc, i) {

        setCurrentid(i);

        const startDate = moment(monthlist_loc[i].start);
        const endDate = moment(monthlist_loc[i].end);

        // Array untuk menyimpan rentang tanggal
        const dateRange = [];

        // Mulai loop dari tanggal awal hingga tanggal akhir
        let currentDate = startDate.clone();
        while (currentDate <= endDate) {

            let view_attendace = null;
            for (let index = 0; index < dataattendance.length; index++) {
                const element = dataattendance[index];
                if (element.date == currentDate.format('YYYY-MM-DD')) {
                    view_attendace = element;
                    break;
                }
            }

            let view_kalender_nasional = null;
            for (let index = 0; index < dataliburnasional.length; index++) {
                const element = dataliburnasional[index];
                if (element.holiday_date == currentDate.format('YYYY-MM-DD')) {
                    view_kalender_nasional = element;
                    break;
                }
            }

            let view_permission = null;
            for (let index = 0; index < datapermission.length; index++) {
                const element = datapermission[index];
                const start_date = moment(element.start_date).format('YYYY-MM-DD');
                const end_date = moment(element.end_date).format('YYYY-MM-DD');
                if (moment(currentDate.format('YYYY-MM-DD')).isBetween(start_date, end_date, undefined, '[]')) {
                    view_permission = element;
                    break;
                }
            }

            let view_shift = null;
            let shift_start = (datashift.length <= 0) ? currentDate.format('YYYY-MM-DD') + ' ' + baseshiftstart : '';
            let shift_end = (datashift.length <= 0) ? currentDate.format('YYYY-MM-DD') + ' ' + baseshiftend : '';
            let offday = 0;
            let cross_day = 0;

            for (let index = 0; index < datashift.length; index++) {
                const element = datashift[index];
                if (element.date == currentDate.format('YYYY-MM-DD')) {
                    view_shift = element;
                    offday = element.offday;
                    cross_day = element.cross_day;
                    if (offday == 0 || offday == '0') {
                        shift_start = currentDate.format('YYYY-MM-DD') + ' ' + element.time_start;
                        shift_end = (element.cross_day == 1 || element.cross_day == '1')
                            ? currentDate.add(1, 'd').format('YYYY-MM-DD') + ' ' + element.time_end
                            : currentDate.format('YYYY-MM-DD') + ' ' + element.time_end;
                    }

                    break;
                }
            }

            // cek keterlambatan
            let is_late = false;
            if (view_attendace != null && offday == 0) {
                // is_late = ()
                const shift = new Date(shift_start);
                const punch = new Date(moment(view_attendace.in_punch_time).format('YYYY-MM-DD HH:mm') + ':00');

                is_late = (shift >= punch) ? false : true;
            }

            // cek pulang lebih awal
            let is_early = false;
            if (view_attendace != null && offday == 0) {
                const shift = new Date(shift_end);
                const punch = new Date(moment(view_attendace.out_punch_time).format('YYYY-MM-DD HH:mm') + ':00');
                is_early = (shift > punch) ? true : false;
            }

            let new_obj = {
                'date': currentDate.format('YYYY-MM-DD'),
                'view_date': currentDate.format('DD MMM YYYY'),
                'view_day': currentDate.format('dddd'),
                'view_attendace': view_attendace,
                'view_kalender_nasional': view_kalender_nasional,
                'view_permission': view_permission,
                'view_shift': view_shift,
                'offday': offday,
                'cross_day': cross_day,
                'shift_start': (shift_start != '') ? moment(shift_start).format('HH:mm') : '',
                'shift_end': (shift_end != '') ? moment(shift_end).format('HH:mm') : '',
                'is_late': is_late,
                'is_early': is_early,
            };
            dateRange.push(new_obj);

            // Tambahkan satu hari ke tanggal saat ini untuk iterasi selanjutnya
            currentDate.add(1, 'days');
        }

        // Output rentang tanggal
        // console.log(dateRange);

        setViewdate(dateRange)

    }

    function listholiday() {
        http.get('ess/attendance/holidaythisyear', { params: '' }).then(response => {

            // console.log('response', response)

            setHolidaythisyear(response.data.data);

            console.log('holidaythisyear', holidaythisyear);
        });
    }


    function loadAttendanceList() {
        http.get('ess/attendance/listattd', { params: '' }).then(response => {

            console.log('response', response)

            setDataattendance(response.data.data_attendance);
            setDataliburnasional(response.data.data_libur_nasional);
            setDatapermission(response.data.data_permission);
            setDatashift(response.data.data_shift);

            if (response.data.data_base_shift != null && response.data.data_base_shift != 'null' && typeof response.data.data_base_shift !== 'undefined') {
                setBaseshiftstart(response.data.data_base_shift.time_start);
                setBaseshiftend(response.data.data_base_shift.time_end);
            } else {
                setBaseshiftstart('');
                setBaseshiftend('');
            }


            setMonthlist(response.data.list_month);

            view_tanggal(response.data.list_month, 0);




            // if (response.data.data.working_hour_id != 0 && response.data.data.working_hour_id != '0') {
            //     setAttendance(response.data.data)
            //     setTime_start(response.data.data.time_start)
            //     setTime_end(response.data.data.time_end)
            //     setWorking_hour(parseInt(response.data.data.working_hour))
            // }


        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        // loadProfile()
        listholiday();
        loadAttendanceList()
        setCurrentYear(moment().format('YYYY'));
        // loadAttendance(month, year)
    }, []);


    return (
        <Container className="mt-5">

            {(monthlist.length <= 0) ?
                (<h3>Loading...</h3>)
                : (
                    <Row className="mb-3 justify-content-md-center">

                        <Col xs={12} sm={12} md={4}>
                            <FloatingLabel controlId="floatingSelect" label="Pilih Bulan">
                                <Form.Select onChange={updateMonth} aria-label="Floating label select example" defaultValue={current_id_month}>
                                    {monthlist.map((month, index) =>
                                        <option key={index} value={index}>{month.label}</option>
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>

                        {/* <Col xs={6} sm={2} md={2}>
                            <Button variant="primary">Kirim</Button>
                        </Col> */}
                    </Row>
                )}


            {(monthlist.length <= 0) ? '' : (
                <Row className="mt-4 justify-content-md-center">
                    <Col xs={12} sm={12} md={12}>


                        <h3 className='mt-5'>List Absensi

                            <Button variant="primary" style={{ textAlign: 'right', float: 'right' }} onClick={refrshtabel}>Refresh Tabel</Button>
                            {/* <Button variant="info" style={{ textAlign: 'right', float: 'right', marginRight: '10px' }} onClick={handleShow}>Libur Nasional {currentYear}</Button> */}
                        </h3>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Libur Nasional {currentYear}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Table bordered hover>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '3%' }}>#</th>
                                            <th style={{ width: '30%' }}>Tanggal</th>
                                            <th>Keterangan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holidaythisyear.map((item, index2) =>
                                            <tr>
                                                <td>{index2 + 1}</td>
                                                <td>{moment(item.holiday_date).format('DD MMM YYYY')}</td>
                                                <td>{item.descriptions}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Tutup
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <hr />
                        <Table responsive striped bordered gap={3} style={{ background: '#ffffff' }}>
                            <thead>
                                <tr style={{ textAlign: 'center' }}>
                                    <th style={{ width: '3%' }}>#</th>
                                    <th style={{ width: '10%' }}>Tanggal</th>
                                    {(datashift.length > 0) ? (<th style={{ width: '10%' }}>Shift</th>) : ''}
                                    <th style={{ width: '10%' }}>In</th>
                                    <th style={{ width: '10%' }}>Out</th>
                                    <th style={{ width: '5%' }}>WH</th>
                                    <th>Perizinan</th>
                                    <th>Libur Nasional</th>
                                </tr>
                            </thead>
                            <tbody>
                                {viewDate.map((attd, index) =>
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                        {(moment(attd.date).day() == 0 || moment(attd.date).day() == 6)
                                            ? (<td style={{ whiteSpace: 'nowrap', color: '#ff9d0d' }}>
                                                <b>{attd.view_day}</b>
                                                <br />
                                                {attd.view_date}
                                            </td>)
                                            : (<td style={{ whiteSpace: 'nowrap' }}>
                                                <b>{attd.view_day}</b>
                                                <br />
                                                {attd.view_date}
                                            </td>)}

                                        {(datashift.length > 0) ? (
                                            <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                {(attd.offday == 1)
                                                    ? 'Off Day'
                                                    : attd.shift_start + ' - ' + attd.shift_end}
                                            </td>) : ''}



                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                            {
                                                (attd.view_attendace != null)
                                                    ? (
                                                        <div>
                                                            {moment(attd.view_attendace.in_punch_time).format('DD MMM YYYY')}
                                                            <br />

                                                            {
                                                                (attd.is_late)
                                                                    ? (<span style={{ color: 'red' }}>
                                                                        {moment(attd.view_attendace.in_punch_time).format('HH:mm')}
                                                                    </span>) : moment(attd.view_attendace.in_punch_time).format('HH:mm')
                                                            }
                                                        </div>
                                                    )
                                                    : ''
                                            }
                                        </td>

                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                                            {
                                                (attd.view_attendace != null)
                                                    ? (
                                                        <div>
                                                            {moment(attd.view_attendace.out_punch_time).format('DD MMM YYYY')}
                                                            <br />
                                                            {
                                                                (attd.is_early)
                                                                    ? (<span style={{ color: 'red' }}>
                                                                        {moment(attd.view_attendace.out_punch_time).format('HH:mm')}
                                                                    </span>)
                                                                    : moment(attd.view_attendace.out_punch_time).format('HH:mm')
                                                            }
                                                        </div>
                                                    )
                                                    : ''
                                            }
                                        </td>

                                        <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                            {
                                                (attd.view_attendace != null) ? attd.view_attendace.working_hours : ''
                                            }
                                        </td>
                                        <td>
                                            {

                                                (attd.view_permission != null)
                                                    ?

                                                    (
                                                        <div>
                                                            {attd.view_permission.leave_category_name} |
                                                            {(attd.view_permission.type == 0) ? (<Badge bg="success">Tidak Potong Gaji / Cuti</Badge>)
                                                                : (
                                                                    (attd.view_permission.type == 1) ? (<Badge bg="warning" text="dark">
                                                                        Potong Cuti
                                                                    </Badge>) : (<Badge bg="danger">Potong Gaji</Badge>)
                                                                )}
                                                            <br />
                                                            <span style={{ fontSize: '14px' }}>{attd.view_permission.reason}</span>
                                                        </div>
                                                    )
                                                    : ''
                                            }
                                        </td>

                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            {
                                                (attd.view_kalender_nasional != null) ? attd.view_kalender_nasional.descriptions : ''
                                            }
                                        </td>

                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}



        </Container>
    );
};

export default Attendance;