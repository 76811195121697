import React, { useState, useEffect, useCallback } from 'react';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import http from "../../_services/http-common";

import CountdownTimer from './CountdownTimer';

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/id';

const Maintenance = () => {

  const [maintenance, setMaintenance] = useState(true);
  const [maintenancedone, setMaintenancedone] = useState('');

  const [difference, setDifference] = useState(null);

  function checkMaintenance() {
    http.get('ess/login/checkmaintenance').then(response => {

      if (parseInt(response.data.status) == 1) {
        setMaintenance(true);


        const givenDate = new Date(response.data.timer);
        const currentDate = new Date();

        setMaintenancedone(response.data.timer);

        // console.log('givenDate', givenDate);
        // console.log('currentDate', currentDate);

        const diffInSeconds = Math.abs((currentDate - givenDate) / 1000);
        setDifference(diffInSeconds.toFixed(0));

      } else {
        setMaintenance(false);
      }

      // console.log('maintenance', maintenance);

    }).catch(error => {
      console.log(error)
      // setLoadingPage(false)
    });
  }

  useEffect(() => {
    checkMaintenance()
  }, []);


  return (
    <Card style={{ padding: '15px' }}>
      <Card.Body>
        <Card.Title>Maintenance!</Card.Title>
        <Card.Text>
          Mohon maaf saat ini <b>ESS</b> sedang <i>maintenence</i>, kami akan berusaha kembali sesegera mungkin.
          <br />
          <br />

          {(difference > 0) ? (
            <div>
              <CountdownTimer initialTime={difference} />
              <Moment format="DD MMM YYYY HH:mm" withTitle>
                {maintenancedone}
              </Moment>
            </div>
          ) : ''}

          <br />
          <br />
          Terima kasih
          <br /> - IT Rimau
        </Card.Text>
        {/* {maintenance} */}
      </Card.Body>
    </Card>
  );
}

export default Maintenance;