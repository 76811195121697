import React, { useState, useEffect } from 'react';
import {
    Table, Container, Modal, Button, Form,
    FloatingLabel, Row, Col,
    Alert,
    Spinner
} from "react-bootstrap";
import http from "../../_services/http-common";
// import { ProfileContext } from '../../_context/ProfileContext';

import { toast } from 'react-toastify';
import Select from 'react-select'

function TableDataComponent(props){
    let { text, value, newValue } = props
    newValue = newValue ? newValue : '-'
    value = value ? value : '-'
    return(
        <tr>
            <td style={{ background: '#f7f7f7' }}>{text}</td>
            <td>{newValue}</td>
            {
                (newValue === value)
                    ? <td>{value}</td>
                    : <td style={{ background: '#e7ffe8' }}>{value}</td>
            }
        </tr>
    )
}

function InputComponent(props){
    const { label, value, isDisabled, onChange } = props
    return(
        <FloatingLabel
            controlId='floatingInput'
            label={label}
            className="mb-3"
        >
            <Form.Control onChange={onChange} type='text' placeholder={label} value={value} disabled={isDisabled} />
        </FloatingLabel>
    )
}

const Changeprofile = () => {

    const [profile, setProfile] = useState();

    const [nik, setnik] = useState('');
    const [fingerprint_id, setfingerprint_id] = useState('');

    const [name, setName] = useState('');
    const updateName = e => {
        setName(e.target.value)
    }
    const [ktp_number, setKtp_number] = useState('');
    const updateKtp_number = e => {
        setKtp_number(e.target.value);
    }

    const [npwp, setNpwp] = useState('');
    const updateNpwp = e => {
        setNpwp(e.target.value);
    }

    const [title_ahead, setTitle_ahead] = useState('');
    const updateTitle_ahead = e => {
        setTitle_ahead(e.target.value)
    }

    const [title_behind, setTitle_behind] = useState('');
    const updateTitle_behind = e => {
        setTitle_behind(e.target.value)
    }

    const [religions, setReligions] = useState([]);
    const [religion_name, setReligion_name] = useState('');
    const [religion_id, setReligion_id] = useState('');
    const updateReligion_id = e => {
        var index = e.nativeEvent.target.selectedIndex;
        setReligion_name(e.nativeEvent.target[index].text)
        setReligion_id(e.target.value);
    }

    const [address, setAddress] = useState('');
    const updateAddress = e => {
        setAddress(e.target.value)
    }

    const [blood, setBlood] = useState('A');
    const updateBlood = e => {
        setBlood(e.target.value);
    }


    const [email, setEmail] = useState('');
    const updateEmail = e => {
        setEmail(e.target.value);
    }

    const [phone, setPhone] = useState('');
    const updatePhone = e => {
        setPhone(e.target.value);
    }

    const [gender, setGender] = useState('');
    const updateGender = e => {
        setGender(e.target.value);
    }

    const [place_of_birth, setPlace_of_birth] = useState('');
    const updatePlace_of_birth = e => {
        setPlace_of_birth(e.target.value);
    }

    const [date_of_birth, setDate_of_birth] = useState('');
    const updateDate_of_birth = e => {
        setDate_of_birth(e.target.value);
    }

    const [listbank, setListBank] = useState([]);
    const [bank_index, setBank_index] = useState(0);
    const [bank_name, setBank_name] = useState('');
    const [bank_id, setBank_id] = useState();
    const updateBank_id = e => {
        setBank_name(e.label)
        setBank_id(e.value)
        setBank_index(e.index)
    }

    const [bank_account_number, setBank_account_number] = useState('');
    const updateBank_account_number = e => {
        setBank_account_number(e.target.value)
    }

    const [bank_account_name, setBank_account_name] = useState('');
    const updateBank_account_name = e => {
        setBank_account_name(e.target.value)
    }

    const [bpjs_ks_no, setbpjs_ks_no] = useState('')
    const updatebpjs_ks_no = e =>{
        setbpjs_ks_no(e.target.value)
    }
    
    const [faskes_1, setfaskes_1] = useState('')
    const updatefaskes_1 = e =>{
        setfaskes_1(e.target.value)
    }
    const [bpjs_tk_no, setbpjs_tk_no] = useState('')
    const updatebpjs_tk_no = e =>{
        setbpjs_tk_no(e.target.value)
    }
    
    const [mother_name, setmother_name] = useState('')
    const updatemotherName = e =>{
        setmother_name(e.target.value)
    }

    const [status_pajak_list, setstatus_pajak_list] = useState([])
    const [status_pajak_name, setstatus_pajak_name] = useState('')
    const [status_pajak_id, setstatus_pajak_id] = useState('')
    const updatestatus_pajak_id = e =>{
        const newValue = e.target.value
        status_pajak_list.forEach((sp)=>{
            if(sp.status_pajak_id === newValue){
                return setstatus_pajak_name(sp.name)
            }
        })
        setstatus_pajak_id(newValue)
    }

    async function loadProfile() {
        return await http.get('ess/profile/detail').then(response => {
            setProfile(response.data.data);

            setstatus_pajak_name(response.data.data.status_pajak_name)
            setstatus_pajak_id(response.data.data.status_pajak_id)
            setmother_name(response.data.data.mother_name)
            setbpjs_tk_no(response.data.data.bpjs_tk_no)
            setfaskes_1(response.data.data.faskes_1)
            setbpjs_ks_no(response.data.data.bpjs_ks_no)
            setnik(response.data.data.nik)
            setfingerprint_id(response.data.data.fingerprint_id)
            setName(response.data.data.name)
            setKtp_number(response.data.data.ktp_number)
            setNpwp(response.data.data.npwp)
            setTitle_ahead(response.data.data.title_ahead)
            setTitle_behind(response.data.data.title_behind)
            setReligion_id(response.data.data.religion_id)
            setReligion_name(response.data.data.religion_name)
            setAddress(response.data.data.address)
            setBlood(response.data.data.blood)
            setEmail(response.data.data.email)
            setPhone(response.data.data.phone)
            setGender(response.data.data.gender)
            setPlace_of_birth(response.data.data.place_of_birth)
            setDate_of_birth(response.data.data.date_of_birth)
            setBank_id(response.data.data.bank_id)
            setBank_name(response.data.data.bank_name)
            loadBank(response.data.data.bank_id)
            setBank_account_number(response.data.data.bank_account_number)
            setBank_account_name(response.data.data.bank_account_name)
        }).catch(error => {
            console.log(error)
        });
    }

    async function loadStatusPajak(){
        return await http.get('ess/profile/master',{
            params:{
                table: 'status_pajak',
                order_column: 'status_pajak_id', order_type: 'ASC'
            }
        }).then(res => {
            setstatus_pajak_list(res.data.data)
        }).catch(error =>{
            console.log(error);
        })
    }

    async function loadReligions() {
        return await http.get('ess/profile/master', {
            params: {
                table: 'religions',
                order_column: 'religion_id', order_type: 'ASC'
            }
        }).then(response => {
            setReligions(response.data.data)
        }).catch(error => {
            console.log(error)
        });
    }

    async function loadBank(f_bank_id) {
        return await http.get('ess/profile/master', {
            params: {
                table: 'bank',
                order_column: 'name', order_type: 'ASC'
            }
        }).then(response => {
            response.data.data.map((v, i) => {
                if (v.bank_id === f_bank_id) {
                    setBank_index(i)
                }
                return setListBank(current => [...current, { index: i, value: v.bank_id, label: v.code + ' - ' + v.name }]);
            })
        }).catch(error => {
            console.log(error)
        });
    }

    async function loadCheckRequest() {
        return await http.get('ess/profile/changeprofile').then(response => {
            if (response.data.status) {
                setExisting(response.data.data)
            }
        }).catch(error => {
            console.log(error)
        });
    }

    const [isLoadingPage, setLoadingPage] = useState(true);
    const [existing, setExisting] = useState(null);

    useEffect(() => {
        async function init(){
            await loadCheckRequest()
            await loadProfile()
            await loadReligions()
            await loadStatusPajak()
            setLoadingPage(false)
        }
        init()
    }, []);

    const [isLoading, setLoading] = useState(false);
    function validasiProfile() {
        setShow(true)
        console.log('profile', profile);
    }

    function submitProfile() {
        let data_after = {
            0: {
                label: 'KTP',
                column: 'ktp_number',
                value: ktp_number,
                is_change: (profile.ktp_number === ktp_number) ? 0 : 1,
                before: profile.ktp_number,
                is_master: 0
            },
            1: {
                label: 'NPWP',
                column: 'npwp',
                value: npwp,
                is_change: (profile.npwp === npwp) ? 0 : 1,
                before: profile.npwp,
                is_master: 0
            },
            2: {
                label: 'Status Pajak',
                column: 'status_pajak_id',
                value: status_pajak_id ,
                is_change: (profile.status_pajak_id === status_pajak_id) ? 0 : 1,
                before: profile.status_pajak_id,
                is_master: 1,
                master_before: profile.status_pajak_name,
                master_after: status_pajak_name,
            },
            3: {
                label: 'No BPJS KETENAGAKERJAAN',
                value: bpjs_tk_no,
                is_change: (profile.bpjs_tk_no === bpjs_tk_no) ? 0 : 1,
                before: profile.bpjs_tk_no,
                is_master: 0,
                column : 'bpjs_tk_no',
                is_diff_table : '1',
                table_name: 'bpjs_tk',
            },
            4: {
                label: 'No BPJS KESEHATAN',
                value: bpjs_ks_no,
                is_change: (profile.bpjs_ks_no === bpjs_ks_no) ? 0 : 1,
                before: profile.bpjs_ks_no,
                is_master: 0,
                column : 'bpjs_ks_no',
                is_diff_table : '1',
                table_name: 'bpjs_ks',
            },
            5: {
                label: 'Faskes 1',
                value: faskes_1,
                is_change: (profile.faskes_1 === faskes_1) ? 0 : 1,
                before: profile.faskes_1,
                is_master: 0,
                column : 'faskes_1',
                is_diff_table : '1',
                table_name: 'bpjs_ks',
            },
            6: {
                label: 'Nama',
                column: 'name',
                value: name,
                is_change: (profile.name === name) ? 0 : 1,
                before: profile.name,
                is_master: 0
            },
            7: {
                label: 'Agama',
                column: 'religion_id',
                value: religion_id ,
                is_change: (profile.religion_id === religion_id) ? 0 : 1,
                before: profile.religion_id,
                is_master: 1,
                master_before: profile.religion_name,
                master_after: religion_name,
            },
            8: {
                label: 'Golongan Darah',
                column: 'blood',
                value: blood ,
                is_change: (profile.blood === blood) ? 0 : 1,
                before: profile.blood,
                is_master: 0
            },
            9: {
                label: 'Jenis Kelamin',
                column: 'gender',
                value: gender ,
                is_change: (profile.gender === gender) ? 0 : 1,
                before: profile.gender,
                is_master: 0
            },
            10: {
                label: 'Tempat Lahir',
                column: 'place_of_birth',
                value: place_of_birth ,
                is_change: (profile.place_of_birth === place_of_birth) ? 0 : 1,
                before: profile.place_of_birth
            },
            11: {
                label: 'Tanggal Lahir',
                column: 'date_of_birth',
                value: date_of_birth ,
                is_change: (profile.date_of_birth === date_of_birth) ? 0 : 1,
                before: profile.date_of_birth,
                is_master: 0
            },
            12: {
                label: 'Nama Ibu Kandung',
                column: 'mother_name',
                value: mother_name,
                is_change: (profile.mother_name === mother_name) ? 0 : 1,
                before: profile.mother_name,
                is_master: 0
            },
            13: {
                label: 'Gelar Sebelum Nama',
                column: 'title_ahead',
                value: title_ahead,
                is_change: (profile.title_ahead === title_ahead) ? 0 : 1,
                before: profile.title_ahead,
                is_master: 0
            },
            14: {
                label: 'Gelar Setelah Nama',
                column: 'title_behind',
                value: title_behind ,
                is_change: (profile.title_behind === title_behind) ? 0 : 1,
                before: profile.title_behind,
                is_master: 0
            },
            15: {
                label: 'Alamat',
                column: 'address',
                value: address,
                is_change: (profile.address === address) ? 0 : 1,
                before: profile.address,
                is_master: 0
            },
            16: {
                label: 'E-mail',
                column: 'email',
                value: email ,
                is_change: (profile.email === email) ? 0 : 1,
                before: profile.email,
                is_master: 0
            },
            17: {
                label: 'No Hp',
                column: 'phone',
                value: phone ,
                is_change: (profile.phone === phone) ? 0 : 1,
                before: profile.phone,
                is_master: 0
            },

            18: {
                label: 'Bank',
                column: 'bank_id',
                value: bank_id ,
                is_change: (profile.bank_id === bank_id) ? 0 : 1,
                before: profile.bank_id,
                is_master: 1,
                master_before: profile.bank_name,
                master_after: bank_name,
            },
            19: {
                label: 'Nomor Rekening',
                column: 'bank_account_number',
                value: bank_account_number ,
                is_change: (profile.bank_account_number === bank_account_number) ? 0 : 1,
                before: profile.bank_account_number,
                is_master: 0
            },
            20: {
                label: 'Nama Akun',
                column: 'bank_account_name',
                value: bank_account_name ,
                is_change: (profile.bank_account_name === bank_account_name) ? 0 : 1,
                before: profile.bank_account_name,
                is_master: 0
            },
        };

        setLoading(true)

        setTimeout(() => {
            setLoading(false)
        }, 10000);

        const params = new URLSearchParams()
        params.append('formData', JSON.stringify(data_after))
        http.post('ess/profile/changeprofile', params).then(response => {
            if (response.data.status) {
                toast.success("Perubahan terkirim")
                setTimeout(() => {
                    window.location.replace('profile')
                }, 1500);
            } else {
                toast.error("Anda memiliki permintaan perubahan yang belum ditanggapi")
                setLoading(false)
            }
        }).catch(error => {
            toast.error("Terjadi kesalahan, cobalah beberapa saat lagi")
            setLoading(false)
        });

    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    return (
        <>
            {(isLoadingPage)
                ?
                <Container className="mt-5">
                    <div className='d-flex align-items-center gap-3'>
                        <Spinner animation="border" variant="primary" />
                        <h5 className='m-0'>
                            Loading...
                        </h5>
                    </div>    
                </Container>
                :

                ((existing) ?

                    <Container className="mt-5">
                        <Row className="justify-content-md-center">
                            <Col md={8}>
                                <Alert variant='primary'>
                                    Menuggu tanggapan dari Admin HRD
                                </Alert>
                                <Table responsive bordered gap={3} style={{ background: '#ffffff' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%', textAlign: 'center' }}>Atribut</th>
                                            <th style={{ width: '40%', textAlign: 'center' }}>Sebelum</th>
                                            <th style={{ textAlign: 'center' }}>Sesudah</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {existing.formData.map((obj, i) => {

                                            var tr = (obj.is_master === 1) ?
                                                <tr key={i + 1}>
                                                    <td>{obj.label}</td>
                                                    <td>{obj.master_before ? obj.master_before : '-' }</td>
                                                    {(obj.is_change === 1)
                                                        ? <td style={{ background: '#e7ffe8' }}>{obj.master_after ? obj.master_after : '-' }</td> : <td>{obj.master_after ? obj.master_after : '-'}</td>}
                                                </tr>
                                                :
                                                <tr key={i + 1}>
                                                    <td>{obj.label}</td>
                                                    <td>{obj.before ? obj.before : '-'}</td>
                                                    {(obj.is_change === 1) ? <td style={{ background: '#e7ffe8' }}>{obj.value ? obj.value : '-'}</td> : <td>{obj.value ? obj.value : '-'}</td>}
                                                </tr>;

                                            return tr
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>

                    :

                    <Container className="mt-5">
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <h3>Data Diri</h3>
                                <hr />
                                <InputComponent label='NIK' value={nik} isDisabled={true} />
                                <InputComponent label='Fingerprint ID' value={fingerprint_id} isDisabled={true} />
                                <InputComponent label='KTP' value={ktp_number} onChange={updateKtp_number} />
                                <InputComponent label='NPWP' value={npwp} onChange={updateNpwp} />
                                <FloatingLabel className='mb-3' controlId="floatingSelect" label="Status Pajak">
                                    <Form.Select onChange={updatestatus_pajak_id} aria-label="Status Pajak" value={status_pajak_id ? status_pajak_id : ''} >
                                        <option style={status_pajak_id ? {display: 'none'} : {display: 'block'}} key='nullValue' value=''>-</option>
                                        {status_pajak_list.map((status_pajak, index) => {
                                            return <option key={index + 1} value={status_pajak.status_pajak_id}>{status_pajak.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                <InputComponent label='No BPJS KETENAGAKERJAAN' value={bpjs_tk_no} onChange={updatebpjs_tk_no} />
                                <InputComponent label='No BPJS KESEHATAN' value={bpjs_ks_no} onChange={updatebpjs_ks_no} />
                                <InputComponent label='Faskes 1' value={faskes_1} onChange={updatefaskes_1} />
                            </Col>

                            <Col md={3}>
                                <h3>Profil</h3>
                                <hr />
                                <InputComponent label='Nama Lengkap' value={name} onChange={updateName} />
                                <FloatingLabel className='mb-3' controlId="floatingSelect" label="Agama">
                                    <Form.Select onChange={updateReligion_id} aria-label="Agama" value={religion_id} >
                                        {religions.map((religion, index) => {
                                            return <option key={index + 1} value={religion.religion_id}>{religion.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel className='mb-3' controlId="floatingSelect" label="Golongan Darah">
                                    <Form.Select onChange={updateBlood} aria-label="Golongan Darah" value={blood}>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="AB">AB</option>
                                        <option value="O">O</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <FloatingLabel className='mb-3' controlId="floatingSelect" label="Jenis Kelamin">
                                    <Form.Select onChange={updateGender} aria-label="Jenis Kelamin" value={gender}>
                                        <option value="Male">Laki - laki</option>
                                        <option value="Female">Perempuan</option>
                                    </Form.Select>
                                </FloatingLabel>
                                <InputComponent className='mb-3' label='Tempat Lahir' value={place_of_birth} onChange={updatePlace_of_birth} />
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Tanggal Lahir"
                                    className="mb-3"
                                >
                                    <Form.Control placeholder='Tanggal' type='date' value={date_of_birth} onChange={updateDate_of_birth} />
                                </FloatingLabel>
                                <InputComponent label='Nama Ibu Kandung' value={mother_name} onChange={updatemotherName} />
                            </Col>
                            <Col md={3}>
                                <h3>Lain-lain</h3>
                                <hr />
                                <InputComponent label='Gelar Sebelum Nama' value={title_ahead} onChange={updateTitle_ahead} />
                                <InputComponent label='Gelar Setelah Nama' value={title_behind} onChange={updateTitle_behind} />
                                <FloatingLabel className='mb-3' controlId="floatingTextarea2"
                                    label="Alamat">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Alamat"
                                        style={{ height: '150px' }}
                                        value={address}
                                        onChange={updateAddress}
                                    />
                                </FloatingLabel>
                                <InputComponent label='E-mail' value={email} onChange={updateEmail} />
                                <InputComponent label='No Hp' value={phone} onChange={updatePhone} />
                            </Col>

                            <Col md={3}>
                                <h3>Akun Bank</h3>
                                <hr />
                                <div style={{marginBottom: '1rem'}}>
                                    <Select onChange={updateBank_id} value={listbank[bank_index]} options={listbank} />
                                </div>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Nomor Rekening"
                                    className="mb-3"
                                >
                                    <Form.Control placeholder='Nomor Rekening' type="text" value={bank_account_number} onChange={updateBank_account_number} />
                                </FloatingLabel>

                                <InputComponent label='Nama Akun' value={bank_account_name} onChange={updateBank_account_name} />
                            </Col>
                        </Row>

                        <>
                            <Modal
                                size="lg"
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Validasi</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert variant={'warning'}>
                                        Mohon pastikan kembali data yang akan Anda ubah dengan benar.
                                    </Alert>

                                    <Row className="mt-4 mb-5 justify-content-md-center">
                                        <Col xs={12} sm={12} md={12}>
                                            <Table responsive bordered gap={3} style={{ background: '#ffffff' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '20%', textAlign: 'center' }}>Atribut</th>
                                                        <th style={{ width: '40%', textAlign: 'center' }}>Sebelum</th>
                                                        <th style={{ textAlign: 'center' }}>Sesudah</th>
                                                    </tr>
                                                </thead>
                                                {
                                                    (profile)
                                                        ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={3} style={{ textAlign: 'center' }}>
                                                                    <h4>Data Diri</h4>
                                                                </td>
                                                            </tr>
                                                            <TableDataComponent text='KTP' value={ktp_number} newValue={profile.ktp_number} />
                                                            <TableDataComponent text='NPWP' value={npwp} newValue={profile.npwp} />
                                                            <TableDataComponent text='Status Pajak' value={status_pajak_name} newValue={profile.status_pajak_name} />
                                                            <TableDataComponent text='NO BPJS TK' value={bpjs_tk_no} newValue={profile.bpjs_tk_no} />
                                                            {/* <TableDataComponent text='NO BPJS KES' value={bpjs_ks_no} newValue={profile.bpjs_ks_no} />
                                                            <TableDataComponent text='Faskes 1' value={faskes_1} newValue={profile.faskes_1} /> */}
                                                            <tr>
                                                                <td colSpan={3} style={{ textAlign: 'center' }}>
                                                                    <h4>Profil</h4>
                                                                </td>
                                                            </tr>
                                                            <TableDataComponent text='Nama' value={name} newValue={profile.name} />
                                                            <TableDataComponent text='Agama' value={religion_name} newValue={profile.religion_name} />
                                                            <TableDataComponent text='Golongan Darah' value={blood} newValue={profile.blood} />
                                                            <TableDataComponent text='Jenis Kelamin' value={gender} newValue={profile.gender} />
                                                            <TableDataComponent text='Tempat Lahir' value={place_of_birth} newValue={profile.place_of_birth} />
                                                            <TableDataComponent text='Tanggal Lahir' value={date_of_birth} newValue={profile.date_of_birth} />
                                                            <TableDataComponent text='Nama Ibu Kandung' value={mother_name} newValue={profile.mother_name} />
                                                            <tr>
                                                                <td colSpan={3} style={{ textAlign: 'center' }}>
                                                                    <h4>Lain - lain</h4>
                                                                </td>
                                                            </tr>
                                                            <TableDataComponent text='Gelar Sebelum Nama' value={title_ahead} newValue={profile.title_ahead} />
                                                            <TableDataComponent text='Gelar Setelah Nama' value={title_behind} newValue={profile.title_behind} />
                                                            <TableDataComponent text='Alamat' value={address} newValue={profile.address} />
                                                            <TableDataComponent text='Email' value={email} newValue={profile.email} />
                                                            <TableDataComponent text='No Hp' value={phone} newValue={profile.phone} />
                                                            <tr>
                                                                <td colSpan={3} style={{ textAlign: 'center' }}>
                                                                    <h4>Akun Bank</h4>
                                                                </td>
                                                            </tr>
                                                            <TableDataComponent text='Bank' value={bank_name} newValue={profile.bank_name} />
                                                            <TableDataComponent text='Nomor Rekening' value={bank_account_number} newValue={profile.bank_account_number} />
                                                            <TableDataComponent text='Nama Akun' value={bank_account_name} newValue={profile.bank_account_name} />
                                                        </tbody>
                                                        : ''
                                                }

                                            </Table>
                                        </Col>
                                    </Row>


                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Tutup
                                    </Button>
                                    <Button disabled={isLoading} onClick={submitProfile} variant="success">{isLoading ? 'Loading…' : 'Kirim'}</Button>
                                </Modal.Footer>
                            </Modal>
                        </>

                        <Row className="justify-content-md-center">
                            <Col md={12}>
                                <hr />
                                <Button className="float-end" disabled={isLoading} type="submit" onClick={validasiProfile}>
                                    {isLoading ? 'Loading…' : 'Kirim'}
                                </Button>
                            </Col>
                        </Row>
                    </Container>)
            }

        </>


    )
}

export default Changeprofile;

