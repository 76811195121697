import React, { useState, useEffect } from 'react';
import http from "../../_services/http-common";
import { useSearchParams } from 'react-router-dom'
import { Card, Container, Button, Row, Col, Form, FloatingLabel } from "react-bootstrap";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from "react-helmet";

const Changepassword = () => {

    const [isLoading, setLoading] = useState(false);

    const [searchParams] = useSearchParams();
    const [show, setShow] = useState(false);
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertminimum, setAlertminimum] = useState(false);

    const updatePassword = e => {
        setPassword(e.target.value)
        if (e.target.value.length >= 7) {
            setAlertminimum(false)
        } else {
            setAlertminimum(true)
        }
    }
    const updateRepassword = e => {
        setRepassword(e.target.value)
        if (e.target.value == password) {
            setAlert(false)
        } else {
            setAlert(true)
        }
    }

    function checktoken() {

        const params = new URLSearchParams()
        params.append('token', searchParams.get('t'))

        http.post('ess/login/checktokenresetpassword', params).then(response => {
            console.log(response.data)
            if (response.data.status) {
                setShow(true)
                setToken(searchParams.get('t'))
            }
        }).catch(error => {
            console.log(error)
        });

    }

    const submit = e => {
        e.preventDefault()
        if (!alert &&
            !alertminimum) {

            setLoading(true)

            const params = new URLSearchParams()
            params.append('password', password)
            params.append('token', token)

            http.post('ess/login/changepassword', params).then(response => {
                // setLoading(false)
                toast.success('Sukses')
                setTimeout(() => {
                    window.location.replace('/login')
                }, 1500)
            }).catch(error => {
                setLoading(false)
                toast.error('Gagal, silahkan coba lagi')
            })

        } else {

        }
    }

    useEffect(() => {
        checktoken()
    }, []);

    return (<Container>

        <Helmet>
            <style>{'body { padding-top:0px; }'}</style>
        </Helmet>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <Card style={{ background: '#f5f5f5' }}>
                        <Card.Img variant="top" src="AppImages/brand/logo3.png" />
                        <Card.Body>
                            <Card.Title className="mb-4">- Form Ubah Password</Card.Title>
                            {(show) ? <>

                                {(alertminimum) ? <span style={{ color: 'red' }}>Password minimal 7 karakter</span> : ''}
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Password Baru"
                                    className="mb-3"
                                >
                                    <Form.Control placeholder='Password Baru' type="password" value={password} onChange={updatePassword} />
                                </FloatingLabel>


                                {(alert) ? <span style={{ color: 'red' }}>Tidak cocok dengan Password Baru</span> : ''}
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Ulangi Password Baru"
                                    className="mb-3"
                                >
                                    <Form.Control placeholder='Ulangi Password Baru' type="password" value={repassword} onChange={updateRepassword} />
                                </FloatingLabel>


                                <div className="mt-4">
                                    <Button className="float-end"
                                        disabled={isLoading}
                                        onClick={submit} variant="primary">{isLoading ? 'Loading…' : 'Kirim'}</Button>
                                </div>
                            </> :
                                <>
                                    <h5 style={{ color: '#9e9e9e', textAlign: 'center' }}>Token permintaan perubahan password sudah tidak berlaku</h5>
                                    <div className="mt-4">
                                        <hr />
                                        <Button href="/login" variant="outline-secondary"><FontAwesomeIcon icon="fa-solid fa-arrow-left-long" />&nbsp; Login</Button>
                                    </div>
                                </>
                            }
                        </Card.Body>
                    </Card>
                </div>

            </Col>
        </Row>
    </Container>)
};

export default Changepassword;