import React, { useState, useEffect } from 'react';
import { Table, Container, Modal, Button, Form, FloatingLabel, Row, Col } from "react-bootstrap";
import http from "../../_services/http-common";

import Moment from 'react-moment';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'react-toastify';

const Leavehistory = () => {

    const [histories, setHistory] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);

    function loadHistories() {
        http.get('ess/profile/leavehistory').then(response => {
            // console.log(response.data.data)
            // checkAllowingPermission()
            setFirstLoading(false);
            setHistory(response.data.data);

        }).catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        loadHistories()
    }, []);

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col xs={12} sm={8} md={8}>
                    <h3>Riwayat Cuti</h3>
                    <hr />

                    {(histories.length > 0) ? <Table striped gap={3} style={{ background: '#ffffff' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '1%', textAlign: 'center' }}>No</th>
                                <th style={{ textAlign: 'center' }}>Deskripsi</th>
                                <th style={{ width: '7%', textAlign: 'center' }}>Aksi</th>
                                <th style={{ width: '25%', textAlign: 'center' }}>Tanggal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {histories.map((history, index) =>
                                <tr key={index}>
                                    <td style={{ textAlign: 'center' }}>{index + 1}</td>

                                    {(history.leave_id != 0)
                                        ? <td>
                                            {(history.type == 1) ? <span>[<span style={{ color: '#ff5722' }}>PEMBATALAN</span>]</span> : ''}
                                            {history.leave_category_name}
                                            <div style={{ fontSize: '10px', color: '#7c7c7c' }}>
                                                {moment(history.start_date).format('DD MMM YYYY HH:mm')} - {moment(history.end_date).format('DD MMM YYYY HH:mm')}
                                            </div>
                                        </td> : <td>Penyesuaian By HR Staff</td>}


                                    <td>
                                        {(history.type == 1)
                                            ? <div style={{ color: '#4caf50' }}><b style={{ textAlign: 'left' }}>+</b> <b style={{ float: 'right' }}> {history.value}</b></div>
                                            : <div style={{ color: '#ff5722' }}><b style={{ textAlign: 'left' }}>-</b> <b style={{ float: 'right' }}> {history.value}</b></div>}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>{moment(history.insert_at).format('DD MMMM YYYY HH:mm')}</td>
                                </tr>
                            )
                            }
                        </tbody>
                    </Table> : (firstLoading) ? 'Loading...' : 'Belum ada riwayat cuti'}


                </Col>
            </Row>
        </Container>
    );
};

export default Leavehistory;