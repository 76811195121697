import { type } from '@testing-library/user-event/dist/type';
import React, { useState, createContext, useEffect } from 'react';
import http from "../_services/http-common";

export const ProfileContext = createContext();

export const ProfileProvider = (props) => {

    // let data_profile = localStorage.getItem('data_profile');
    // data_profile = (data_profile != null && data_profile != 'null' && typeof data_profile !== 'undefined')
    //     ? JSON.parse(data_profile) : '';

    // console.log(data_profile);
    const [profile, setProfile] = useState({});

    useEffect(() => {
        let isAuth = localStorage.getItem('auth');
        if (isAuth) {
            http.get('ess/profile/detail').then(response => {
                console.log('response 2',response);
                setProfile(response.data.data);
            }).catch(error => {
                console.log(error)
            });
        }

    }, []);

    return (
        <ProfileContext.Provider value={[profile, setProfile]}>
            {props.children}
        </ProfileContext.Provider>
    );
}  