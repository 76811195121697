import { useEffect } from "react";
import { useState } from "react";
import {
    Button,
    Collapse,
    Container,
    FloatingLabel,
    Form,
    Spinner,
} from "react-bootstrap";
import http from "../../_services/http-common";

function InputComponent(props) {
    const { label, value, isDisabled, onChange, type = "text" } = props;
    return (
        <FloatingLabel
            controlId="floatingInput"
            label={label}
            className="w-100 mb-3"
        >
            <Form.Control
                onChange={onChange}
                type={type}
                placeholder={label}
                defaultValue={value}
                disabled={isDisabled}
            />
        </FloatingLabel>
    );
}

function PerOrangComponent(props) {
    const windowWidth = window.innerWidth;
    const [open, setOpen] = useState(windowWidth < 400 ? false : true);
    const { index, setnewData, newData, Mfamily } = props;

    const [name, setname] = useState(newData[index].name);
    const [family_id, setfamily_id] = useState(newData[index].family_id);
    const [phone, setphone] = useState(newData[index].phone);
    const [place_of_birth, setplace_of_birth] = useState(
        newData[index].place_of_birth
    );
    const [date_of_birth, setdate_of_birth] = useState(
        newData[index].date_of_birth
    );
    const [bpjs_ks_no, setbpjs_ks_no] = useState(newData[index].bpjs_ks_no);
    const [faskes_1, setfaskes_1] = useState(newData[index].faskes_1);

    function changeValue(e, setFunction) {
        setFunction(e.target.value);

        const tempData = [...newData];
        tempData.splice(index, 1, {
            name: name,
            family_id: family_id,
            no_hp: phone,
            place_of_birth: place_of_birth,
            date_of_birth: date_of_birth,
            bpjs_ks_no: bpjs_ks_no,
            faskes_1: faskes_1,
        });
        setnewData(tempData);
    }

    return (
        <>
            <h6
                className="mt-3 border-bottom border-warning"
                onClick={() => setOpen(!open)}
            >
                {index + 1}. {name}
            </h6>
            <Collapse in={open}>
                <div>
                    <div className="d-flex flex-column flex-md-row">
                        <InputComponent
                            label="Nama"
                            value={name}
                            onChange={(e) => {
                                changeValue(e, setname);
                            }}
                        />
                        <FloatingLabel
                            className="mb-3 w-100"
                            controlId="floatingSelect"
                            label="Status Hubungan"
                        >
                            <Form.Select
                                onChange={(e) => changeValue(e, setfamily_id)}
                                aria-label="Status Hubungan"
                                value={family_id}
                            >
                                {Mfamily.map((mf) => {
                                    return (
                                        <option
                                            key={mf.family_id}
                                            value={mf.family_id}
                                        >
                                            {mf.name}
                                        </option>
                                    );
                                })}
                            </Form.Select>
                        </FloatingLabel>
                        <InputComponent
                            label="No HP"
                            value={phone}
                            onChange={(e) => changeValue(e, setphone)}
                        />
                        <InputComponent
                            label="Tempat Lahir"
                            value={place_of_birth}
                            onChange={(e) => changeValue(e, setplace_of_birth)}
                        />
                        <InputComponent
                            label="Tanggal Lahir"
                            type="date"
                            value={date_of_birth}
                            onChange={(e) => changeValue(e, setdate_of_birth)}
                        />
                        <InputComponent
                            label="No BPJS Kesehatan"
                            value={bpjs_ks_no}
                            onChange={(e) => changeValue(e, setbpjs_ks_no)}
                        />
                        <InputComponent
                            label="Faskes 1"
                            value={faskes_1}
                            onChange={(e) => changeValue(e, setfaskes_1)}
                        />
                    </div>
                </div>
            </Collapse>
        </>
    );
}

export default function ChangeFamily() {
    const [newData, setnewData] = useState([]);
    const [Mfamily, setMfamily] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    async function getFamily() {
        return await http
            .get("ess/profile/changefamily")
            .then((res) => setnewData(res.data.data))
            .then((err) => err);
    }

    async function familyMaster() {
        return await http
            .get("ess/profile/master", {
                params: {
                    table: "family",
                    order_column: "family_id",
                    order_type: "ASC",
                },
            })
            .then((res) => {
                setMfamily(res.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        familyMaster();
        getFamily();
        setIsLoading(false);
    }, []);

    return isLoading ? (
        <Container className="mt-5">
            <div className="d-flex align-items-center gap-3">
                <Spinner animation="border" variant="primary" />
                <h5 className="m-0">Loading...</h5>
            </div>
        </Container>
    ) : (
        <Container fluid className="mt-5 px-3 px-md-5">
            <h3>Data Keluarga</h3>
            {newData.map((data, index) => (
                <PerOrangComponent
                    setnewData={setnewData}
                    newData={newData}
                    key={index}
                    index={index}
                    Mfamily={Mfamily}
                />
            ))}
            <div className="d-flex gap-3 justify-content-end mt-3">
                {/* <Button className="btn btn-light btn-outline-primary">
                    Tambah Keluarga
                </Button> */}
                <Button
                    onClick={() => console.log(newData)}
                    className="btn btn-primary"
                >
                    Simpan
                </Button>
            </div>
        </Container>
    );
}
